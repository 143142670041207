import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import { persister, queryClient } from '../../../persistStorage/persistStorage';
import React from 'react';

const CustomQueryProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister }}>
      {children}
    </PersistQueryClientProvider>
  );
};

export default CustomQueryProvider;
