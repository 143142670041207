import { FC, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import { FreeCourse, LawCourse } from '../../../models';
import { cutString } from '../../../helpers/commonHelpers';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import law from '../../../assets/images/signUp/law.svg';
import close from '../../../assets/images/signUp/close.svg';

interface IProps {
  lawCourseList: LawCourse[];
  labelName?: string;
  description?: any;
  noLawCourse?: boolean;
}

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const TooltipLawCourse: FC<IProps> = ({ lawCourseList, labelName, description }) => {
  const [open, setOpen] = useState(false);
  let timeout: number | undefined;

  const price = lawCourseList.reduce((acc: number, item) => acc + Number(item.price), 0);
  function handleTimeoutTooltipClose() {
    timeout = window.setTimeout(handleTooltipClose, 500);
  }
  const handleTooltipOpen = () => {
    window.clearInterval(timeout);
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const renderDescription = () => {
    if (description) {
      return <div className={'b-tooltipCourse__title'}>{description}</div>;
    } else {
      return (
        <div className={'b-tooltipCourse__title'}>
          State Insurance Law Course Included.
        </div>
      );
    }
  };

  const tooltipContent = (
    <div
      className={'b-tooltipCourse__content'}
      onMouseMove={handleTooltipOpen}
      onMouseLeave={handleTooltipClose}
    >
      <div className={'b-tooltipCourse__head'}>
        {renderDescription()}
        <div
          className={'b-tooltipCourse__close-button'}
          onClick={handleTooltipClose}
        ></div>
      </div>
      <div
        className={`b-tooltipCourse__cousesList ${
          lawCourseList.length > 3 && 'scroll__free'
        }`}
      >
        {lawCourseList.map((course: LawCourse) => {
          return (
            <div className={'b-tooltipCourse__course'} key={`law-course-${course.id}`}>
              <div>
                <div className={'b-tooltipCourse__name'}>{course.name}</div>
                <div className={'b-tooltipCourse__descr'}>
                  {cutString(course.description, 100)}
                </div>
              </div>
              <img src={law} alt="law" />
            </div>
          );
        })}
      </div>
      {lawCourseList.length ? (
        <>
          <div className="b-tooltipCourse__divider" />
          <div className="b-tooltipCourse__price">
            <div className="b-tooltipCourse__priceName">Price</div>
            <div className="b-tooltipCourse__priceValue">${price}</div>
          </div>
        </>
      ) : null}
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        open={open}
        title={tooltipContent}
        TransitionComponent={Zoom}
        placement="top"
      >
        <div
          className="b-tooltipCourse"
          onMouseMove={handleTooltipOpen}
          onMouseLeave={handleTimeoutTooltipClose}
        >
          <div className="d-flex">
            <div className="b-tooltipCourse__imgAdd" />
            <div className="b-tooltipCourse__imgLaw" />
          </div>
          <div className="b-tooltipCourse__text ml-1">
            {labelName ? labelName : 'Law course'}
          </div>
        </div>
      </Tooltip>
    </ThemeProvider>
  );
};

export default TooltipLawCourse;
