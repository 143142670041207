import { QueryClient } from '@tanstack/react-query';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';

export const REQUEST_TIME_LIFE = 1000 * 60 * 60 * 48;
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: REQUEST_TIME_LIFE,
    },
  },
});

export const persister = createSyncStoragePersister({
  storage: window.localStorage,
});
