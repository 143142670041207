import React from 'react';
import { arc } from 'd3-shape';
import CountUp from 'react-countup';
import useIsDarkTheme from '../../../hooks/use-is-dark-theme';

const Speedometer = ({
  // eslint-disable-next-line react/prop-types
  value = 60,
  // eslint-disable-next-line react/prop-types
  min = 0,
  // eslint-disable-next-line react/prop-types
  max = 100,
  // eslint-disable-next-line react/prop-types
  label = 'You Scored',
  // eslint-disable-next-line react/prop-types
  scoreRed = 69,
  // eslint-disable-next-line react/prop-types
  scoreYellow = 89,
  // eslint-disable-next-line react/prop-types
  scoreGreen = 90,
}) => {
  const isDarkTheme = useIsDarkTheme();

  const backgroundWhite = arc()
    .innerRadius(0.9)
    .outerRadius(0.88)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(0.2)();

  const backgroundGrey = arc()
    .innerRadius(0.9)
    .outerRadius(0.84)
    .startAngle(-Math.PI / 2)
    .endAngle(Math.PI / 2)
    .cornerRadius(0.2)();

  const mainArc = arc()
    .innerRadius(0.96)
    .outerRadius(0.96)
    .startAngle(-Math.PI / 2)
    // .padAngle(0.02)
    // .cornerRadius(0.2)
    .endAngle(-Math.PI / 2 + `${value / 100}` * Math.PI)();

  const mainCoords = mainArc;

  const redArc = arc()
    .innerRadius(1)
    .outerRadius(0.92)
    .startAngle(-Math.PI / 2)
    // .endAngle(Number(`0.${scoreRed}`)) // 0.69
    // .endAngle(Number(`0.2`))
    .endAngle(-Math.PI / 2 + `0.${scoreRed}` * Math.PI)
    .padAngle(0.02)
    .cornerRadius(1)();

  const yellowArc = arc()
    .innerRadius(1)
    .outerRadius(0.92)
    .startAngle(-Math.PI / 2 + `0.${scoreRed}` * Math.PI)
    .endAngle(-Math.PI / 2 + `0.${scoreYellow}` * Math.PI)
    // .padAngle(0.02)
    .cornerRadius(2)();

  const greenArc = arc()
    .innerRadius(1)
    .outerRadius(0.92)
    .startAngle(-Math.PI / 2 + `0.${scoreGreen}` * Math.PI)
    .endAngle(Math.PI / 2)
    // .padAngle(0.02)
    .cornerRadius(2)();

  const getBlobColor = value => {
    if (value >= 0 && value <= scoreRed) return '#E76262'; //red
    if (value > scoreRed && value <= scoreYellow) return '#F9CF4A'; // yellow
    if (value > scoreYellow && value <= 100) return '#3ECE80'; // green
  };

  return (
    <div className="b-speedometer">
      <div className="b-speedometer__score">{min}</div>
      <div
        style={{
          textAlign: 'center',
          width: '205px',
        }}
      >
        <svg viewBox={[-1.1, -1.1, 2.2, 1.2].join(' ')}>
          <path d={backgroundGrey} fill={isDarkTheme ? '#4E5268' : '#F6F6F6'} />
          <path d={backgroundWhite} fill={isDarkTheme ? '#2d3042' : '#FFFFFF'} />
          <path d={redArc} fill="#E76262" />
          <path d={yellowArc} fill="#F9CF4A" />
          <path d={greenArc} fill="#3ECE80" />
          <path id={'main-arc'} d={mainCoords} fill="blue" />
          <circle
            r="0.07"
            strokeWidth="0.04"
            fill={isDarkTheme ? '#2d3042' : '#fff'}
            stroke={getBlobColor(value)}
            id={'audit-circle'}
          >
            <animateMotion dur="3s" repeatCount="0.5" fill={'freeze'}>
              <mpath xlinkHref="#main-arc"></mpath>
            </animateMotion>
          </circle>
        </svg>

        <div
          style={{
            marginTop: '-70px',
            fontSize: '40px',
            fontWeight: 700,
            fontFeatureSettings: "'zero', 'tnum' 1",
          }}
        >
          <CountUp start={0} end={value} delay={0} duration={1.2}>
            {({ countUpRef }) => (
              <div>
                <span ref={countUpRef} />%
              </div>
            )}
          </CountUp>
          {/*{format(',')(value)}*/}
          {/*{'%'}*/}
        </div>

        {!!label && <div className="b-speedometer__label">{label}</div>}
      </div>
      <div className="b-speedometer__score">{max}</div>
    </div>
  );
};
export default Speedometer;
