import React from 'react';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CourseModel } from '../../../models';
import { KeyboardArrowDown } from '@mui/icons-material';

interface IProps {
  handleChangeCourse: (event: SelectChangeEvent<number | 'Select Course'>) => void;
  selectedCourseId: number | null;
  courses: CourseModel[];
}

const CourseSelector = ({ handleChangeCourse, selectedCourseId, courses }: IProps) => {
  return (
    <>
      <span className="b-qtool__courseSelect-title">Course:</span>
      <Select
        className={`b-formGroup__select`}
        variant="standard"
        disableUnderline={true}
        onChange={handleChangeCourse}
        value={selectedCourseId ?? 'Select Course'}
        IconComponent={() => <KeyboardArrowDown />}
        renderValue={
          selectedCourseId
            ? undefined
            : () => <span style={{ color: 'gray' }}>Select Course</span>
        }
      >
        {(courses || []).map(course => (
          <MenuItem key={`co-${course.id}`} value={course.id}>
            {course.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default CourseSelector;
