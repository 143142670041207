import React, { FC, useState } from 'react';

import ExamScoreStepInner from '../../common/ExamScoreStepInner/ExamScoreStepInner';
import SectionArrow from '@mui/icons-material/KeyboardArrowUp';
import question_ic from '../../../assets/images/score/question_ic.svg';
import correct_ic from '../../../assets/images/score/correct_ic.svg';
import missed_ic from '../../../assets/images/score/missed_ic.svg';
import { getAvailableAudio } from '../../../store/courses/coursesThunk';
import { useAppDispatch } from '../../../hooks/global';
import { ExamDataModel, QuestionScoreStepModel } from '../../../models';

const HEADER_OFFSET = 100;

interface Props {
  exam: ExamDataModel;
  isHideAnswers?: boolean;
  isPrintView?: boolean;
  isForQTool?: boolean;
  scrollBehavior?: 'smooth' | 'auto';
}

const ExamScoreCategoryStep: FC<Props> = ({
  exam,
  isHideAnswers,
  isPrintView = false,
  isForQTool = false,
  scrollBehavior = 'smooth',
}) => {
  const [availableAudio, setAvailableAudio] = useState<boolean>(false);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const idxSubCategoriesList = Object.keys(exam['sub_categories']);
  const examScoreStep = idxSubCategoriesList.map(
    id => exam['sub_categories'][Number(id)],
  );
  const questions = examScoreStep.map(item => item.questions);
  const [questionsList, setQuestionsList] = useState<
    { [p: number]: QuestionScoreStepModel }[]
  >(questions || {});
  const { category_name: title } = exam;
  const totalValue = examScoreStep.reduce((acc: number, item) => acc + item.total_q, 0);
  const correctValue = examScoreStep.reduce(
    (acc: number, item) => acc + item.correct_q,
    0,
  );

  const correctPercentValue = (
    (examScoreStep.reduce((acc: number, item) => acc + item.correct_q, 0) /
      examScoreStep.reduce((acc: number, item) => acc + item.total_q, 0)) *
    100
  ).toFixed(0);

  const scrollToElement = (element: HTMLElement, scrollBehavior: string) => {
    const scrollActions: { [key: string]: () => void } = {
      smooth: () => {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }, 200);
      },
      auto: () => {
        setTimeout(() => {
          element.scrollIntoView({ behavior: 'auto', block: 'start' });
          window.scrollBy(0, -HEADER_OFFSET);
        }, 100);
      },
    };

    scrollActions[scrollBehavior]?.();
  };

  const missedValue = examScoreStep.reduce((acc: number, item) => acc + item.missed_q, 0);

  const handleOpenStep = () => {
    setOpen(!isOpen);
    const element = document.getElementById(`examBlock-${exam.category_id}`);
    if (element && !isOpen) {
      scrollToElement(element, scrollBehavior);
    }

    if (!availableAudio && !isPrintView) {
      setQuestionsList([]);
      questionsList.map(item => {
        const payload = {
          ids: Object.values(item).map(q => String(isForQTool ? q.id : q.question_id)),
        };
        const tempQuestion = { ...item };
        dispatch(getAvailableAudio(payload))
          .unwrap()
          .then(response => {
            response.audio_expl_details.map(audio => {
              if (tempQuestion[audio.id] && audio.expl) {
                tempQuestion[audio.id].audio_expl = audio.link;
              }
            });
            setAvailableAudio(true);
            setQuestionsList(prevState => [...prevState, tempQuestion]);
          });
      });
    }
  };
  return (
    <div
      className={`b-examScoreStep 
          ${isOpen ? 'state_open' : ''}`}
    >
      <button
        className="b-examScoreStep__header"
        id={`examBlock-${exam.category_id}`}
        onClick={handleOpenStep}
      >
        <span className="b-examScoreStep__title">{title}</span>
        <SectionArrow className="b-examScoreStep__arrow" />

        <span className="b-examScoreStep__listInfo">
          <span className="b-examScoreStep__listItem">
            <img src={question_ic} alt="Question" className="b-examScoreStep__listIcon" />
            <span className="b-examScoreStep__listValue">{totalValue}</span>
            <span className="b-examScoreStep__listText">Total Questions</span>
          </span>
          <span className="b-examScoreStep__listItem">
            <img src={correct_ic} alt="Correct" className="b-examScoreStep__listIcon" />
            <span className="b-examScoreStep__listValue">{correctValue}</span>
            <span className="b-examScoreStep__listText">
              Correct ({correctPercentValue}%)
            </span>
          </span>
          <span className="b-examScoreStep__listItem">
            <img src={missed_ic} alt="Missed" className="b-examScoreStep__listIcon" />
            <span className="b-examScoreStep__listValue">{missedValue}</span>
            <span className="b-examScoreStep__listText">Missed</span>
          </span>
        </span>
      </button>

      <div className={`b-examScoreStep__contentWrapper ${isOpen ? 'state_open' : ''}`}>
        <div className="b-examScoreStep__content">
          {examScoreStep.map((exam, idx: number) => {
            return (
              <ExamScoreStepInner
                key={idx}
                title={exam.sub_category_name}
                totalValue={exam.total_q}
                correctValue={exam.correct_q}
                correctPercentValue={exam.correct_q_per}
                missedValue={exam.missed_q}
                questionsList={Object.values(exam.questions || [])}
                isHideAnswers={isHideAnswers}
                isPrintView={isPrintView}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default ExamScoreCategoryStep;
