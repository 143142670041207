import {
  PositionsByCategoryModel,
  QuestionAnswerRateModel,
  SelectedExamCategoriesModel,
} from '../../../../models';
import React, { useEffect, useState } from 'react';
import QuestionTypesExam, {
  QuestionType,
} from '../../../common/QuestionTypesExam/QuestionTypesExam';
import WorkOnMistakesExam, {
  WorkOnMistakesType,
} from '../../../common/WorkOnMistakesExam/WorkOnMistakesExam';
import CustomExamTypeSwitchButton from '../../../controls/CustomExamTypeSwitchButton/CustomExamTypeSwitchButton';
import { BackdropSpinner } from '../../../controls';
import api from '../../../../service/api';
import { CustomExamType } from '../QToolCreateCustomExam';

export const MAX_NUMBER_OF_QUESTIONS_FOR_EXAM = 130;

interface IProps {
  categories: PositionsByCategoryModel[];
  selectedCategories: SelectedExamCategoriesModel[];
  onCollectStep2ForCustomExam: (
    selectedTypeQuestion: QuestionType,
    totalQuestions: number,
  ) => void;
  onCollectStep2ForAdvancedExam: (
    fixedAmount: number,
    threshold: number,
    thresholdNumberOfQuestions: number,
  ) => void;
  renderNavBtn: React.ReactNode;
  customExamType: CustomExamType;
  handleCustomExamTypeChange: () => void;
  courseId: string | undefined;
  selectedWorkOnMistakesType: WorkOnMistakesType;
  setSelectedWorkOnMistakesType: (type: WorkOnMistakesType) => void;
  thresholdNumberOfQuestions: number;
  setThresholdNumberOfQuestions: (questionsNumber: number) => void;
}

const CreateExamStep2 = ({
  categories,
  selectedCategories,
  onCollectStep2ForCustomExam,
  onCollectStep2ForAdvancedExam,
  renderNavBtn,
  handleCustomExamTypeChange,
  customExamType,
  courseId,
  ...props
}: IProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<QuestionAnswerRateModel[] | null>(null);

  const fetchQuestions = async () => {
    try {
      const response = (
        await api.post(
          `/student/course/${courseId}/advanced_exam/categorized_question_answer_rate/`,
          JSON.stringify({
            categories: selectedCategories,
          }),
        )
      ).data as QuestionAnswerRateModel[];
      setQuestions(response);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  if (isLoading) {
    return <BackdropSpinner />;
  }

  return (
    <div className="b-qtool__createExamStep">
      <CustomExamTypeSwitchButton
        customExamType={customExamType}
        onSwitch={handleCustomExamTypeChange}
      />
      <div className="questions-and-mistakes-wrapper">
        <QuestionTypesExam
          categories={categories}
          selectedCategories={selectedCategories}
          onCollectStep2ForCustomExam={onCollectStep2ForCustomExam}
          isActive={customExamType === 'questionTypes'}
        />
        <div className="exam-types-divider"></div>
        <WorkOnMistakesExam
          categories={categories}
          selectedCategories={selectedCategories}
          onCollectStep2ForAdvancedExam={onCollectStep2ForAdvancedExam}
          isActive={customExamType === 'workOnMistakes'}
          courseId={courseId}
          questions={questions}
          {...props}
        />
      </div>
      {renderNavBtn}
    </div>
  );
};

export default CreateExamStep2;
