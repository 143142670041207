import React, { Fragment } from 'react';
import { PositionsByCategoryModel, SelectedExamCategoriesModel } from '../../../models';
import { AdvancedData, CategoryAchievements, CustomTooltip } from '../index';
import { Checkbox, FormControlLabel } from '@mui/material';
import { KeyValueModel } from '../../../models/global';
import { ReactComponent as LeftArrow } from '../../../assets/images/icons/arrow_ic_16x16.svg';

interface IProps {
  categoriesList: PositionsByCategoryModel[];
  isSubCategory: boolean;
  clickCallback: (c: PositionsByCategoryModel) => void;
  selectedCategories: KeyValueModel<SelectedExamCategoriesModel>;
  openedCategory: PositionsByCategoryModel | null;
  handleLeaderboardClick: (
    event: any,
    categoryId: number,
    subCategoryId?: number,
  ) => void;
  onShowSubCategories: (category: PositionsByCategoryModel) => void;
}

const CategoryTitle = ({
  categoriesList,
  isSubCategory,
  clickCallback,
  selectedCategories,
  openedCategory,
  handleLeaderboardClick,
  onShowSubCategories,
}: IProps) => {
  return (
    <>
      {categoriesList.map(c => {
        const isChecked = isSubCategory
          ? Boolean(
              selectedCategories[openedCategory!.category_id]?.sub_categories?.includes(
                c.category_id,
              ),
            )
          : Boolean(selectedCategories[c.category_id]);
        return (
          <div
            key={`question-${c.position}`}
            className="category category-wrapper"
            onClick={event => {
              const target = event.target as HTMLElement;
              if (target.tagName !== 'INPUT') {
                onShowSubCategories(c);
              }
            }}
          >
            <div className="data-wrapper">
              <div className="d-flex flex-column justify-content-between">
                <div className="d-flex align-items-center w-100 pt-3">
                  <CustomTooltip title="Checking this box creates a proportionally weighted exam from all the topics in this section.">
                    <Checkbox
                      checked={isChecked}
                      onChange={() => clickCallback(c)}
                      sx={{
                        color: 'var(--text-color)',
                        paddingLeft: '0.5rem',
                      }}
                    />
                  </CustomTooltip>
                  <FormControlLabel
                    className="check ml-0"
                    label={<span className="title">{c.category_name}</span>}
                    control={<></>}
                  />
                </div>
                <CategoryAchievements category={c} isSubCategory={false} />
              </div>
              <AdvancedData
                category={c}
                handleLeaderboardClick={handleLeaderboardClick}
              />
            </div>
            <CustomTooltip title="Click here to choose one or more of the section's topics to create a custom exam.">
              <span className="btn-subcategory-wrapper">
                <button className="btn-subcategory">
                  <LeftArrow />
                </button>
              </span>
            </CustomTooltip>
          </div>
        );
      })}
    </>
  );
};

export default CategoryTitle;
