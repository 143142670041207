import React, { FC } from 'react';
import { getFormattedDateToUS } from '../../../../helpers/commonHelpers';
import qtoolReleaseImgOne from '../../../../assets/images/notifications/gtool_release_pic_1.webp';
import qtoolReleaseImgTwo from '../../../../assets/images/notifications/gtool_release_pic_2.webp';

interface IProps {
  date_created: string;
}

const QToolRelease: FC<IProps> = ({ date_created }) => {
  return (
    <div className="b-notification__releaseWrappper">
      <p className="b-notification__date overflow-hidden text-left">
        {getFormattedDateToUS(date_created)}
      </p>
      <p className="b-notification__notification">
        New to <b>Extra Credit</b> is <b>Tailored Testing!</b><br />You have asked and we have
        answered! Created as a tool to supplement your PassMasters course, we highly
        suggest you complete your course route before working in Tailored Testing.
        <br />
        Within Tailored Testing you can create <b>Custom Exams</b> and{' '}
        <b>Simulated Final Exams</b>{' '}
        from your course’s pool of questions. WOOO HOOO! When creating a Custom Exam, you
        have a choice of two “modes”, you can create an exam based on “Question Types” or
        you can “Focus on Corrections”. Graphics guide your exam creation journey,
        highlighting your strengths and weaknesses. When taking a Simulated Final Exam,
        you will have a properly weighted, timed exam, giving you an opportunity to work
        through any testing anxiety you may have. You won’t know your Simulated Final Exam
        score until you complete the exam, just like at the real test! As always, we want
        you to focus on understanding the concepts, what is right about the right answer
        and what is wrong with the rest.
        <br />
        Try not to memorize the questions, your goal is to <b>MASTER</b> the concepts and{' '}
        <b>PASS</b> your exam.
        <br />
        <b>HAPPY STUDIES!</b>
      </p>
      <img
        className="b-notification__image"
        src={qtoolReleaseImgTwo}
        alt="qtool release image Two"
      />
      <img
        className="b-notification__image"
        src={qtoolReleaseImgOne}
        alt="qtool release image One"
      />
    </div>
  );
};

export default QToolRelease;
