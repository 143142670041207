import React, { FC } from 'react';
import missed_ic from '../../../assets/images/score/missed_ic.svg';
import correct_ic from '../../../assets/images/score/correct_ic.svg';
import { AnswersQuizEnum } from '../../../constants/course';
import { ReactComponent as SelectedAnswerIcon } from '../../../assets/images/icons/check_ic_16x16.svg';
import { ReactComponent as IncorrectIcon } from '../../../assets/images/icons/incorrect_ic_16x16.svg';
import { PrintAnswerModel, PrintQuestionModel } from '../../../models';
import { sanitizedHTML } from '../../../helpers/commonHelpers';

interface Props {
  question: PrintQuestionModel;
  index: number;
  totalQuestionsNumber: number;
}

const QuestionWithAnswers: FC<Props> = ({ question, index, totalQuestionsNumber }) => {
  const isIncorrectAnswer = (
    question: PrintQuestionModel,
    answer: PrintAnswerModel,
  ): boolean => {
    return (
      question.question_answer_id !== null &&
      question.question_answer_id === answer.id &&
      !answer.is_correct
    );
  };

  const shouldDisplayIconText = (
    answer: PrintAnswerModel,
    question: PrintQuestionModel,
  ) => {
    return (
      !answer.is_correct &&
      !(question.question_answer_id && question.question_answer_id === answer.id)
    );
  };

  return (
    <div className="printContainer">
      <div className="d-flex flex-column flex-lg-row mb-2 mt-1 border b-questionWithAnswers__questionData">
        <div className="d-flex w-100 w-lg-75 p-2">
          <div className="d-flex align-items-center">
            {question.answer_status ? (
              <img src={correct_ic} alt="Correct" className="b-examScoreStep__listIcon" />
            ) : (
              <img src={missed_ic} alt="Missed" className="b-examScoreStep__listIcon" />
            )}
            <p className="ml-auto">
              Question {index + 1} of {totalQuestionsNumber}
            </p>
          </div>
          <p className="ml-auto mr-1">
            {index + 1}/{totalQuestionsNumber}
          </p>
        </div>
      </div>
      <p
        className="b-questionWithAnswers__questionText"
        dangerouslySetInnerHTML={{
          __html: sanitizedHTML(question.text),
        }}
      ></p>
      <div className="b-questionWithAnswers__answers">
        <div className="b-answerOptions">
          {question.answers.map(answer => (
            <label
              key={answer.id}
              className={`b-answerOptions__answerLabel
                ${answer.is_correct ? '-correct' : ''}
                ${isIncorrectAnswer(question, answer) ? '-incorrect' : ''}
              `}
              htmlFor={`${answer.id}`}
            >
              <span className="b-answerOptions__answerWrapper">
                {answer.is_correct ? (
                  <SelectedAnswerIcon className="b-answerOptions__selectedAnswer" />
                ) : (
                  ''
                )}
                {isIncorrectAnswer(question, answer) ? (
                  <IncorrectIcon className="b-answerOptions__incorrectAnswer" />
                ) : (
                  ''
                )}
                {shouldDisplayIconText(answer, question) && (
                  <span className="b-answerOptions__iconText">
                    {AnswersQuizEnum[answer.position - 1]}
                  </span>
                )}
              </span>
              <span
                className={`b-answerOptions__text
                ${answer.is_correct ? 'b-answerOptions__selectedAnswer' : ''}
                ${
                  isIncorrectAnswer(question, answer)
                    ? 'b-answerOptions__incorrectAnswer'
                    : ''
                }
              `}
              >
                {answer.text}
              </span>
            </label>
          ))}
        </div>
      </div>
      <div className="mb-5">
        <div className="d-flex py-2">
          <p className="ml-1 b-questionWithAnswers__explanationFull border-0 p-0">
            Explanation
          </p>
          <p className="ml-5 b-scoreQuestionInfo__id border-0 p-0 align-content-center">
            ID: {question.id}
          </p>
        </div>
        <p
          className="ml-1"
          dangerouslySetInnerHTML={{
            __html: sanitizedHTML(question.expl),
          }}
        ></p>
      </div>
    </div>
  );
};

export default QuestionWithAnswers;
