import { Switch, SwitchProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import FormControlLabel from '@mui/material/FormControlLabel';
import { CustomExamType } from '../../pages/QToolCreateCustomExam/QToolCreateCustomExam';

const FORM_CONTROL_SX_STYLES = {
  marginRight: '0',
  marginLeft: '0',
  alignSelf: 'self-start',
  '& .MuiFormControlLabel-label': {
    font: '600 20px / 32px "Epilogue", sans-serif;',
  },
};

const SWITCH_SX_STYLES = {
  width: '63px !important',
  height: '36px  !important',
  borderRadius: '36px',
};

interface IProps {
  onSwitch: (val: CustomExamType) => void;
  customExamType: CustomExamType;
}

const CustomExamTypeSwitchButton = ({ onSwitch, customExamType }: IProps) => {
  const handleSwitchClick = () => {
    const newType: CustomExamType =
      customExamType === 'questionTypes' ? 'workOnMistakes' : 'questionTypes';
    onSwitch(newType);
  };

  const StyledSwitch = styled((props: SwitchProps) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      sx={SWITCH_SX_STYLES}
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(27px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          backgroundColor: '#41cae7',
          opacity: 1,
          border: 0,
          ...(theme.palette.mode === 'dark'
            ? {
                backgroundColor: '#41cae7',
              }
            : {}),
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#41cae7',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color: theme.palette.grey[100],
        ...(theme.palette.mode === 'dark'
          ? {
              color: theme.palette.grey[600],
            }
          : {}),
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.7,
        ...(theme.palette.mode === 'dark'
          ? {
              opacity: 0.3,
            }
          : {}),
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 32,
      height: 32,
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      backgroundColor: '#41cae7',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
      ...(theme.palette.mode === 'dark'
        ? {
            backgroundColor: '#41cae7',
          }
        : {}),
    },
  }));

  return (
    <div className="align-self-center">
      <FormControlLabel
        control={
          <StyledSwitch
            checked={customExamType === 'workOnMistakes'}
            onClick={handleSwitchClick}
          />
        }
        sx={FORM_CONTROL_SX_STYLES}
        label="Select Testing Type"
        labelPlacement="top"
      />
    </div>
  );
};

export default CustomExamTypeSwitchButton;
