import React, { FC, useEffect, useState } from 'react';
import { ReactComponent as NotificationIcon } from '../../../assets/images/icons/notification_ic.svg';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import OtgMp3Release from '../../pages/Notifications/OtgMp3Release/OtgMp3Release';
import useIsDarkTheme from '../../../hooks/use-is-dark-theme';
import { getProfile } from '../../../store/user/userThunk';
import { UserNotificationsModel } from '../../../models';
import api from '../../../service/api';
import QToolRelease from '../../pages/Notifications/QToolRelease/QToolRelease';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const borderStyle = {
  marginRight: '20px',
  marginLeft: '24px',
}

const UserNotification: FC = () => {
  const [isNotificationDialogOpened, setIsNotificationDialogOpened] =
    useState<boolean>(false);
  const [notificationAliases, setNotificationAliases] = useState<string[]>([]);
  const [notificationIds, setNotificationIds] = useState<number[]>([]);
  const [notifications, setNotifications] = useState<UserNotificationsModel[]>([]);

  const countNotification = useAppSelector(state => state.user.user?.count_notification);

  const dispatch = useAppDispatch();
  const isDarkTheme = useIsDarkTheme();

  useEffect(() => {
    const notificationAliases: string[] = [];
    const notificationIds: number[] = [];
    if (notifications && notifications.length > 0) {
      notifications.forEach(item => {
        notificationAliases.push(item.alias);
        notificationIds.push(item.id);
      });
      setNotificationAliases(notificationAliases);
      setNotificationIds(notificationIds);
    }
  }, [notifications]);

  useEffect(() => {
    fetchNotifications();
  }, [countNotification]);

  const markNotificationsAsSeen = async (notification_ids: number[]) => {
    try {
      await api.post('/student/user_notification/see_notification/', {
        notification_ids,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const fetchNotifications = async () => {
    try {
      const response = (await api.get(
        'student/user_notification/',
      )) as UserNotificationsModel[];
      setNotifications(response);
    } catch (error) {
      console.error(error);
    }
  };

  const onCloseClick = () => {
    setIsNotificationDialogOpened(false);
    if (!countNotification) return;
    markNotificationsAsSeen(notificationIds)
      .then(() => dispatch(getProfile()))
      .catch(err => console.error(err));
  };

  const onOpenNotificationClick = () => {
    setIsNotificationDialogOpened(true);
  };

  return (
    <>
      <div
        className={`b-notification__icon ${
          countNotification && countNotification > 0 ? '-has-notifications' : ''
        }`}
      >
        <NotificationIcon
          fill={isDarkTheme ? '#FFFFFF' : '#1E2527'}
          onClick={onOpenNotificationClick}
        />
      </div>
      <Modal
        open={isNotificationDialogOpened}
        onClose={onCloseClick}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle} className="b-notification__modal">
          <div className="b-notification__wrapper">
            <button
              className="b-notification__closeBtn"
              onClick={onCloseClick}
              aria-label="Close Modal"
            />
            <div className="b-notification__title">
              <p>What’s New</p>
            </div>
            <div className="b-notification__content">
              {notificationAliases.map((alias, index) => {
                let NotificationComponent;
                const notification = notifications.find(item => item.alias === alias);
                const dateCreated = notification?.date_created || '';

                switch (alias) {
                  case 'otg_mp3_release':
                    NotificationComponent = OtgMp3Release;
                    break;
                  case 'otg_qtool_release':
                    NotificationComponent = QToolRelease;
                    break;
                  default:
                    NotificationComponent = () => (
                      <div key={`Unknown-${index}`}>Unknown Notification</div>
                    );
                }

                return (
                  <React.Fragment key={alias}>
                    <NotificationComponent date_created={dateCreated} />
                    {index < notificationAliases.length - 1 && (
                      <div style={borderStyle} className="border mt-4 mb-4" />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default UserNotification;
