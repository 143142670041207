import Typography from '@mui/material/Typography';
import { Checkbox } from '@mui/material';
import { NumberOfQuestions } from '../../controls';
import React, { useEffect, useState } from 'react';
import { collectEstimatedTime } from '../../../helpers/commonHelpers';
import type {
  PositionsByCategoryModel,
  SelectedExamCategoriesModel,
} from '../../../models';
import { MAX_NUMBER_OF_QUESTIONS_FOR_EXAM } from '../../pages/QToolCreateCustomExam/parts/CreateExamStep2';

enum QuestionTypesEnum {
  all = 'is_all_questions',
  unused = 'is_unused_questions',
  incorrect = 'is_previously_incorrect',
}

const initQuestionTypes = {
  [QuestionTypesEnum.all]: true,
  [QuestionTypesEnum.unused]: false,
  [QuestionTypesEnum.incorrect]: false,
};

export type QuestionType = Record<QuestionTypesEnum, boolean>;

interface IProps {
  categories: PositionsByCategoryModel[];
  selectedCategories: SelectedExamCategoriesModel[];
  isActive: boolean;
  onCollectStep2ForCustomExam: (
    selectedTypeQuestion: QuestionType,
    totalQuestions: number,
  ) => void;
}

const QuestionTypesExam = ({
  isActive,
  categories,
  selectedCategories,
  onCollectStep2ForCustomExam,
}: IProps) => {
  const [selectedQuestionsType, setSelectedQuestionsType] =
    useState<QuestionType>(initQuestionTypes);
  const [numberOfAllQuestions, setNumberOfAllQuestions] = useState<number>(0);
  const [numberOfSelectedQuestions, setNumberOfSelectedQuestions] =
    useState<number>(numberOfAllQuestions);

  useEffect(() => {
    const totalQ = collectQuestionsCount();
    setNumberOfAllQuestions(totalQ);
    setNumberOfSelectedQuestions(
      totalQ >= MAX_NUMBER_OF_QUESTIONS_FOR_EXAM
        ? MAX_NUMBER_OF_QUESTIONS_FOR_EXAM
        : totalQ,
    );
  }, [selectedQuestionsType]);

  useEffect(() => {
    if (!isActive) return;
    onCollectStep2ForCustomExam(selectedQuestionsType, numberOfSelectedQuestions);
  }, [selectedQuestionsType, numberOfSelectedQuestions, isActive]);

  const handleSelectType = (type: QuestionTypesEnum) => {
    if (!isActive) return;
    if (type === QuestionTypesEnum.all) {
      setSelectedQuestionsType(initQuestionTypes);
    } else {
      setSelectedQuestionsType({
        ...selectedQuestionsType,
        [QuestionTypesEnum.all]: false,
        [type]: !selectedQuestionsType[type],
      });
    }
  };
  const handleNumberQuestion = (val: string) => {
    if (Number(val) >= MAX_NUMBER_OF_QUESTIONS_FOR_EXAM) {
      setNumberOfSelectedQuestions(MAX_NUMBER_OF_QUESTIONS_FOR_EXAM);
      return;
    }
    if (Number(val) >= numberOfAllQuestions) {
      setNumberOfSelectedQuestions(numberOfAllQuestions);
      return;
    }
    setNumberOfSelectedQuestions(Number(val));
  };

  const collectQuestionsCount = () => {
    let questions = 0;
    selectedCategories.map(c => {
      const category = categories.find(f => f.category_id === c.id);
      const selectedCat = selectedCategories.find(f => f.id === c.id);
      category?.sub_categories.map(subCategory => {
        if (!selectedCat?.sub_categories.includes(subCategory.category_id)) {
          return;
        }
        if (selectedQuestionsType[QuestionTypesEnum.all]) {
          questions = questions + subCategory.total_count_of_questions;
        }
        if (selectedQuestionsType[QuestionTypesEnum.incorrect]) {
          questions = questions + subCategory.incorrect_answers;
        }
        if (selectedQuestionsType[QuestionTypesEnum.unused]) {
          questions =
            questions +
            subCategory.total_count_of_questions -
            (subCategory.incorrect_answers + subCategory.correct_answers);
        }
      });
    });

    return questions || 0;
  };

  const totalEstimate = collectEstimatedTime(numberOfSelectedQuestions);

  const renderQuestionTypeTile = (title: string, type: QuestionTypesEnum) => {
    return (
      <div className="question-tile" onClick={() => handleSelectType(type)}>
        <Checkbox
          sx={{
            color: 'var(--text-color)',
          }}
          checked={selectedQuestionsType[type]}
        />
        <span>{title}</span>
      </div>
    );
  };

  return (
    <div className={`question-types-wrapper ${!isActive ? '-not-active' : ''}`}>
      <Typography variant="h6" fontWeight={600} align={'center'}>
        Question Types
      </Typography>
      <div className="question-types">
        {renderQuestionTypeTile(
          'All questions from the selected pool',
          QuestionTypesEnum.all,
        )}
        {renderQuestionTypeTile('Unused questions', QuestionTypesEnum.unused)}
        {renderQuestionTypeTile('Recently incorrect', QuestionTypesEnum.incorrect)}
      </div>
      <NumberOfQuestions
        handleChangeOfQuestionsNumber={handleNumberQuestion}
        initialNumberOfQuestions={numberOfSelectedQuestions}
        totalEstimate={totalEstimate}
        numberOfPossibleQuestions={numberOfAllQuestions}
        totalQuestions={numberOfAllQuestions}
        isDisabled={!isActive}
      />
    </div>
  );
};

export default QuestionTypesExam;
