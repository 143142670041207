import { PositionsByCategoryModel } from '../../../models';
import question_ic from '../../../assets/images/icons/circled-question.svg';
import check_ic from '../../../assets/images/icons/circled-check.svg';
import circle_x_ic from '../../../assets/images/icons/circled-x.svg';
import score_ic from '../../../assets/images/icons/circled-score.svg';
import React from 'react';

interface IProps {
  category: PositionsByCategoryModel;
  isSubCategory: boolean;
}

const CategoryAchievements = ({ category, isSubCategory }: IProps) => {
  return (
    <div className="category-achievements-wrapper ml-0 mr-auto">
      <div className={`info pb-3 ${isSubCategory ? 'marginLeft12' : ''}`}>
        <div className="info-item">
          {!isSubCategory && <img src={question_ic} alt="question_ic" />}
          <div className={isSubCategory ? 'd-flex flex-column mr-2' : 'details'}>
            <b>{category.total_count_of_questions}</b>
            <span className={isSubCategory ? 'mr-2' : 'ml-1'}>Total Q&rsquo;s</span>
          </div>
        </div>
        <div className="info-item">
          <img src={check_ic} alt="check_ic" />
          <div className={isSubCategory ? 'd-flex flex-column mr-2' : 'details'}>
            <b>{category.correct_answers}</b>
            <span className={isSubCategory ? 'mr-2' : 'ml-1'}>Correct</span>
          </div>
        </div>
        <div className="info-item">
          <img src={circle_x_ic} alt="circle_x_ic" />
          <div className={isSubCategory ? 'd-flex flex-column mr-2' : 'details'}>
            <b>{category.incorrect_answers}</b>
            <span className={isSubCategory ? 'mr-2' : 'ml-1'}>Missed</span>
          </div>
        </div>
        <div className="info-item">
          <img src={score_ic} alt="score_ic" className="" />
          <div className={isSubCategory ? 'd-flex flex-column mr-2' : 'details'}>
            <b>{category.score}%</b>
            <span className={isSubCategory ? 'mr-2' : 'ml-1'}>Attempted</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryAchievements;
