import React from 'react';
import { PositionsByCategoryModel } from '../../../models';
import { ReactComponent as Gradient } from '../../../assets/images/exam/gradient_ic.svg';
import { ReactComponent as RedSmiley } from '../../../assets/images/exam/red_smiley_ic.svg';
import { ReactComponent as YellowSmiley } from '../../../assets/images/exam/yellow_smiley_ic.svg';
import { ReactComponent as GreenSmiley } from '../../../assets/images/exam/green_smiley_ic.svg';
import ProgressCircle from '../ProgressCircle/ProgressCircle';
import CustomTooltip from '../CustomTooltip/CustomTooltip';
import leaderboard_ic from '../../../assets/images/exam/leaderboard_ic.svg';

const MIN_BOTTOM_POSITION = 20;
const MAX_BOTTOM_POSITION = 45;
const MIN_LEFT_POSITION = -10;
const MAX_LEFT_POSITION = 190;
const TOP_INDENT = 12;
const LEFT_INDENT = 2;
const RED_ZONE_RATE = 30;
const GREEN_ZONE_RATE = 70;
const CIRCLES = new Array(11).fill(null);

interface IProps {
  category: PositionsByCategoryModel;
  handleLeaderboardClick: (
    event: any,
    categoryId: number,
    subCategoryId?: number,
  ) => void;
  parentCategoryId?: number;
}

const AdvancedData = ({ category, handleLeaderboardClick, parentCategoryId }: IProps) => {
  const getBottomPosition = (percentage: number) => {
    return (
      MIN_BOTTOM_POSITION +
      (percentage / 100) * (MAX_BOTTOM_POSITION - MIN_BOTTOM_POSITION) -
      TOP_INDENT
    );
  };
  const getLeftPosition = (percentage: number) => {
    return (
      MIN_LEFT_POSITION +
      (percentage / 100) * (MAX_LEFT_POSITION - MIN_LEFT_POSITION) +
      LEFT_INDENT
    );
  };
  return (
    <div className="category-achievements-wrapper">
      <div className="advanced-data-wrapper">
        <CustomTooltip title="Success rate">
          <div style={{ position: 'relative' }}>
            {category.percentage_correct_answers < RED_ZONE_RATE && (
              <RedSmiley
                className="position-absolute"
                style={{
                  bottom: getBottomPosition(category.percentage_correct_answers),
                  left: getLeftPosition(category.percentage_correct_answers),
                }}
              />
            )}
            {category.percentage_correct_answers >= RED_ZONE_RATE &&
              category.percentage_correct_answers < GREEN_ZONE_RATE && (
                <YellowSmiley
                  className="position-absolute"
                  style={{
                    bottom: getBottomPosition(category.percentage_correct_answers),
                    left: getLeftPosition(category.percentage_correct_answers),
                  }}
                />
              )}
            {category.percentage_correct_answers >= GREEN_ZONE_RATE && (
              <GreenSmiley
                className="position-absolute"
                style={{
                  bottom: getBottomPosition(category.percentage_correct_answers),
                  left: getLeftPosition(category.percentage_correct_answers),
                }}
              />
            )}
            <Gradient />
            <div className="d-flex justify-content-between">
              {CIRCLES.map((_, index) => (
                <div key={index} className="circle"></div>
              ))}
            </div>
          </div>
        </CustomTooltip>
        <div className="circle-and-icons-wrapper">
          <ProgressCircle
            percentage={category.percentage_correct_answers}
            marginTop={TOP_INDENT}
          />
          <div className="icons-wrapper">
            <CustomTooltip title="Ranking of Missed Questions">
              <span>
                <button
                  className="icon-btn -blue"
                  onClick={
                    parentCategoryId
                      ? () =>
                          handleLeaderboardClick(
                            event,
                            parentCategoryId,
                            category.category_id,
                          )
                      : () => handleLeaderboardClick(event, category.category_id)
                  }
                >
                  <img src={leaderboard_ic} alt="leaderboard_ic" />
                </button>
              </span>
            </CustomTooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdvancedData;
