import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import close from '../../../assets/images/signUp/close.svg';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const TooltipNoLaw = () => {
  const [open, setOpen] = useState(false);

  let timeout: number | undefined;

  function handleTimeoutTooltipClose() {
    timeout = window.setTimeout(handleTooltipClose, 500);
  }
  const handleTooltipOpen = () => {
    window.clearInterval(timeout);
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const tooltipContent = (
    <div
      className={'b-tooltipCourse__content'}
      onMouseMove={handleTooltipOpen}
      onMouseLeave={handleTooltipClose}
    >
      <div className={'b-tooltipCourse__head'}>
        <div className={'b-tooltipCourse__title'}>{'No State Law'}</div>
        <div
          className={'b-tooltipCourse__close-button'}
          onClick={handleTooltipClose}
        ></div>
      </div>
      <div className={`b-tooltipCourse__cousesList`}>
        <div className={'b-tooltipCourse__descr'}>
          This course includes general insurance concepts related to your examination
          only. It does NOT include state specific insurance law.
        </div>
      </div>
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        open={open}
        title={tooltipContent}
        TransitionComponent={Zoom}
        placement="top"
      >
        <div
          className="b-tooltipCourse -noLaw"
          onMouseMove={handleTooltipOpen}
          onMouseLeave={handleTimeoutTooltipClose}
        >
          <div className="b-tooltipCourse__textNoLaw">{'No State Law'}</div>
        </div>
      </Tooltip>
    </ThemeProvider>
  );
};

export default TooltipNoLaw;
