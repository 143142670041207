import React, { useState, forwardRef, useImperativeHandle, ForwardedRef } from 'react';
import { Stack, Typography } from '@mui/material';
import { StopwatchSettings, useStopwatch, useTimer } from 'react-timer-hook';

type IProps = {
  initialSeconds: number;
  label: string;
  type: 'dynamic' | 'static';
};

export interface ExamTimerRef {
  getTotalSeconds: () => number;
}

const ExamTimer = forwardRef(
  ({ initialSeconds, label, type }: IProps, ref: ForwardedRef<ExamTimerRef>) => {
    const [isExpired, setIsExpired] = useState<boolean>(false);
    const expiryTimestamp = new Date();
    expiryTimestamp.setSeconds(expiryTimestamp.getSeconds() + initialSeconds);
    const {
      seconds: timerSeconds,
      minutes: timerMinutes,
      hours: timerHours,
    } = useTimer({
      expiryTimestamp,
      autoStart: type !== 'static',
      onExpire: () => {
        setIsExpired(true);
        start();
      },
    });

    function getTotalSeconds() {
      if (!isExpired) {
        return initialSeconds - (timerSeconds + timerMinutes * 60 + timerHours * 3600);
      } else {
        return (
          initialSeconds +
          stopwatchSeconds +
          stopwatchMinutes * 60 +
          stopwatchHours * 3600
        );
      }
    }

    useImperativeHandle(ref, () => ({
      getTotalSeconds,
    }));

    const getStopwatchSettings = () => {
      const settings: StopwatchSettings = {
        autoStart: false,
      };
      if (initialSeconds <= 0) {
        const stopwatchOffset = new Date();
        stopwatchOffset.setSeconds(stopwatchOffset.getSeconds() + initialSeconds * -1);
        settings.offsetTimestamp = stopwatchOffset;
      }
      return settings;
    };

    const {
      seconds: stopwatchSeconds,
      minutes: stopwatchMinutes,
      hours: stopwatchHours,
      start,
    } = useStopwatch(getStopwatchSettings());

    return (
      <Stack
        sx={{
          background: 'var(--white-black3)',
          borderRadius: '47px',
          p: '8px 20px',
          width: 'max-content',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography
          color={type === 'static' ? '#7C7F80' : isExpired ? '#E34234' : '#39BD86'}
          fontWeight={500}
          fontSize={12}
        >
          {label.toUpperCase()}
        </Typography>
        {!isExpired ? (
          <Typography
            color={type === 'static' ? '#7C7F80' : isExpired ? '#E34234' : '#1E2527'}
            fontWeight={700}
            fontSize={18}
          >
            {timerHours < 10 ? `0${timerHours}` : timerHours}:
            {timerMinutes < 10 ? `0${timerMinutes}` : timerMinutes}:
            {timerSeconds < 10 ? `0${timerSeconds}` : timerSeconds}
          </Typography>
        ) : (
          <Typography
            color={type === 'static' ? '#7C7F80' : isExpired ? '#E34234' : '#1E2527'}
            fontWeight={700}
            fontSize={18}
          >
            {stopwatchHours < 10 ? `0${stopwatchHours}` : stopwatchHours}:
            {stopwatchMinutes < 10 ? `0${stopwatchMinutes}` : stopwatchMinutes}:
            {stopwatchSeconds < 10 ? `0${stopwatchSeconds}` : stopwatchSeconds}
          </Typography>
        )}
      </Stack>
    );
  },
);

export default ExamTimer;
