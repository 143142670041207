import React, { FC, ReactElement } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import ModalSimple from '../ModalSimple/ModalSimple';

interface IProps {
  open: boolean;
  onClose: () => void;
  title?: string | ReactElement;
  text?: string | ReactElement;
  buttons?: {
    left?: {
      title: string;
      onClick?: () => void;
      type?: ButtonProps['type'];
      className?: string;
    };
    right?: {
      title: string;
      onClick?: () => void;
      type?: ButtonProps['type'];
      className?: string;
    };
  };
  customAlertStyle?: any;
}

const AlertModal: FC<IProps> = ({
  open,
  onClose,
  text,
  title,
  buttons,
  customAlertStyle,
}) => {
  return (
    <ModalSimple open={open} onClose={onClose}>
      <div className={`b-alert`} style={customAlertStyle || {}}>
        <div className="b-alert__body">
          {title ? <div className="b-alert__title">{title}</div> : null}
          {text ? <div className="b-alert__text">{text}</div> : null}
        </div>
        {buttons ? (
          <div className="b-alert__footer">
            {buttons.left ? (
              <Button
                onClick={buttons.left.onClick}
                sx={{ marginRight: 1 }}
                type={buttons.left.type}
                className={`b-btn -smallSize -transparent ${buttons.left.title}`}
              >
                <span className="b-btn__text">{buttons.left.title}</span>
              </Button>
            ) : null}
            {buttons.right ? (
              <Button
                onClick={buttons.right.onClick}
                sx={{ marginRight: 1 }}
                type={buttons.right.type}
                className={`b-btn -smallSize ${buttons.right.className}`}
              >
                <span className="b-btn__text">{buttons.right.title}</span>
              </Button>
            ) : null}
          </div>
        ) : null}
      </div>
    </ModalSimple>
  );
};

export default AlertModal;
