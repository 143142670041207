import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import checkbox_ic from '../../../assets/images/icons/checkbox.svg';
import checkbox_checked_ic from '../../../assets/images/icons/checkbox_checked.svg';
import Link from '@mui/material/Link';
import { sanitizedHTML } from '../../../helpers/commonHelpers';

interface ILayoutProps {
  isCheckedLicense: boolean;
  setIsCheckedLicense: React.Dispatch<React.SetStateAction<boolean>>;
  description: string;
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const PreLicenseCheckbox: React.FC<ILayoutProps> = ({
  isCheckedLicense,
  setIsCheckedLicense,
  description,
}: ILayoutProps) => {
  const [modal, setModal] = useState<boolean>(false);

  const onCloseModal = () => {
    setModal(false);
  };

  const handleOpenModal = (e: any) => {
    e.preventDefault();
    setModal(true);
  };

  return (
    <>
      <FormControlLabel
        className="b-authInput__checkbox"
        control={
          <Checkbox
            sx={{
              color: 'var(--text-color)',
              transform: 'scale(1.3)',
            }}
            checked={isCheckedLicense}
            onChange={e => setIsCheckedLicense(e.target.checked)}
            // icon={<img src={checkbox_ic} width={20} height={20} alt="Checkbox" />}
            // checkedIcon={<img src={checkbox_checked_ic} alt="Checkbox" />}
          />
        }
        label={
          <div>
            I understand this insurance course is not approved for prelicensing
            requirements in my state.
            {` "`}
            <Link
              href={'#'}
              onClick={handleOpenModal}
              style={{
                color: '#41CAE7',
                fontWeight: 700,
                marginLeft: 0,
              }}
            >
              See Details
            </Link>
            {`"`}
          </div>
        }
      />
      {modal ? (
        <Modal
          open={modal}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} className="b-modal">
            <div className="b-modal__wrapper">
              <button
                className="b-modal__closeBtn"
                onClick={() => setModal(false)}
                aria-label="Close Modal"
              />
              <div className="b-modal__title">Pre License</div>
              <div className="b-modal__text">
                <p
                  dangerouslySetInnerHTML={{
                    __html: sanitizedHTML(description),
                  }}
                />
              </div>
            </div>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default PreLicenseCheckbox;
