import { CustomQueryProvider } from '../index';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import React from 'react';

interface IProps {
  Component: React.ComponentType<any>;
}

const WithQueryProvider = ({ Component }: IProps) => {
  return (
    <CustomQueryProvider>
      <Component />
      {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen />}
    </CustomQueryProvider>
  );
};

export default WithQueryProvider;
