import React, { FC } from 'react';

import Link from '@mui/material/Link';
import { ROUTES } from '../../../router/routes';
import { Link as RouterLink } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ExamModel } from '../../../models';

interface IProps {
  examNameList: ExamModel[];
}

const result = (exam: ExamModel) => {
  if (Number(exam.score) >= exam.exam_grade_score_green) {
    return <span className={'b-examScoreTable__result -passed'}>Passed</span>;
  }
  if (
    Number(exam.score) > exam.exam_grade_score_red &&
    Number(exam.score) <= exam.exam_grade_score_yellow
  ) {
    return <span className={'b-examScoreTable__result -almost'}>Almost done</span>;
  }
  if (Number(exam.score) <= exam.exam_grade_score_red) {
    return <span className={'b-examScoreTable__result -failed'}>Failed</span>;
  }
  return <span className={'b-examScoreTable__result -almost'}>Almost done</span>;
};

const ExamScoreTable: FC<IProps> = (props: IProps) => {
  const { examNameList } = props;

  return (
    <div className="b-examScoreTable">
      <TableContainer>
        <Table aria-label="exam-score table">
          <TableHead>
            <TableRow>
              <TableCell>Exam</TableCell>
              <TableCell>Result</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Passing Score</TableCell>
              <TableCell>Started</TableCell>
              <TableCell>Finished</TableCell>
              <TableCell>Details</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {examNameList?.map(exam => {
              const routeScoreCategories = ROUTES.COURSE_SCORE_BY_CATEGORIES.replace(
                ':examProgressId',
                String(exam.exam_progress_id),
              );
              return (
                <TableRow key={exam.exam_progress_id}>
                  <TableCell>{exam.exam_name}</TableCell>
                  <TableCell>{result(exam)}</TableCell>
                  <TableCell>
                    <span
                      className={`b-examScoreTable__score 
                      ${
                        Number(exam.score) >= exam.exam_grade_score_green ? '-passed' : ''
                      } 
                      ${
                        Number(exam.score) <= exam.exam_grade_score_red ? '-failed' : ''
                      }`}
                    >
                      {exam.score}%
                    </span>
                  </TableCell>
                  <TableCell>{exam.passing_score}%</TableCell>
                  <TableCell>
                    <div className="b-examScoreTable__date">
                      {exam.date_created && exam.date_created.slice(0, 10)}
                      <br />
                      {exam.date_created && exam.date_created.slice(11, 19)}
                    </div>
                  </TableCell>
                  <TableCell style={{ fontSize: 12, lineHeight: '16px' }}>
                    <div className="b-examScoreTable__date">
                      {exam.date_passed && exam.date_passed.slice(0, 10)}
                      <br />
                      {exam.date_passed && exam.date_passed.slice(11, 19)}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Link
                      className="b-examScoreTable__link"
                      component={RouterLink}
                      to={routeScoreCategories}
                    >
                      See Detail
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ExamScoreTable;
