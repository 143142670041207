import { useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import close from '../../../assets/images/signUp/close.svg';

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const TooltipNoPreLicense = () => {
  const [open, setOpen] = useState(false);

  let timeout: number | undefined;

  function handleTimeoutTooltipClose() {
    timeout = window.setTimeout(handleTooltipClose, 500);
  }
  const handleTooltipOpen = () => {
    window.clearInterval(timeout);
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const tooltipContent = (
    <div
      className={'b-tooltipCourse__content'}
      onMouseMove={handleTooltipOpen}
      onMouseLeave={handleTooltipClose}
    >
      <div className={'b-tooltipCourse__head'}>
        <div className={'b-tooltipCourse__title'}>No Pre-License Requirements</div>
        <div
          className={'b-tooltipCourse__close-button'}
          onClick={handleTooltipClose}
        ></div>
      </div>
      <div className={`b-tooltipCourse__cousesList`}>
        <div className={'b-tooltipCourse__descr'}>
          This state does not have any pre-license training requirements. This course will
          help you <span style={{ color: '#41cae7' }}>MASTER</span> the concepts you need
          to <span style={{ color: '#41cae7' }}>PASS</span> your insurance exam!
        </div>
      </div>
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        open={open}
        title={tooltipContent}
        TransitionComponent={Zoom}
        placement="top"
      >
        <div
          className="b-tooltipCourse -noLicense"
          onMouseMove={handleTooltipOpen}
          onMouseLeave={handleTimeoutTooltipClose}
        >
          <div className="b-tooltipCourse__textNoLicense">No Pre-license req</div>
        </div>
      </Tooltip>
    </ThemeProvider>
  );
};

export default TooltipNoPreLicense;
