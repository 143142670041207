import { FC, useState } from 'react';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

import { FreeCourse } from '../../../models';
import { cutString } from '../../../helpers/commonHelpers';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import freeExam from '../../../assets/images/signUp/free-exam.svg';
import surprise from '../../../assets/images/signUp/surprise.svg';
import close from '../../../assets/images/signUp/close.svg';

interface IProps {
  freeCourseList: FreeCourse[];
}

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: 'transparent',
        },
      },
    },
  },
});

const TooltipFreeCourse: FC<IProps> = ({ freeCourseList }) => {
  const [open, setOpen] = useState(false);

  let timeout: number | undefined;

  function handleTimeoutTooltipClose() {
    timeout = window.setTimeout(handleTooltipClose, 500);
  }
  const handleTooltipOpen = () => {
    window.clearInterval(timeout);
    setOpen(true);
  };

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const tooltipContent = (
    <div
      className={'b-tooltipCourse__content'}
      onMouseMove={handleTooltipOpen}
      onMouseLeave={handleTooltipClose}
    >
      <div className={'b-tooltipCourse__head'}>
        <div className={'b-tooltipCourse__title'}>
          Get the following Courses <br /> as free Bonus!
        </div>
        <div
          className={'b-tooltipCourse__close-button'}
          onClick={handleTooltipClose}
        ></div>
      </div>
      <div
        className={`b-tooltipCourse__cousesList ${
          freeCourseList.length > 3 && 'scroll__free'
        }`}
      >
        {freeCourseList.map((course: FreeCourse) => (
          <div className={'b-tooltipCourse__course'} key={course.id}>
            <div>
              <div className={'b-tooltipCourse__name'}>{course.name}</div>
              <div className={'b-tooltipCourse__descr'}>
                {cutString(course.description, 100)}
              </div>
            </div>

            <img src={surprise} alt="surprise" />
          </div>
        ))}
      </div>
    </div>
  );
  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        open={open}
        // open={true}
        title={tooltipContent}
        TransitionComponent={Zoom}
        placement="top"
      >
        <div
          className="b-tooltipCourse"
          onMouseMove={handleTooltipOpen}
          onMouseLeave={handleTimeoutTooltipClose}
        >
          <img src={freeExam} alt="free axam" />
          <div className="b-tooltipCourse__text">Courses</div>
        </div>
      </Tooltip>
    </ThemeProvider>
  );
};

export default TooltipFreeCourse;
