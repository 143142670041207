import NOTIFICATION_IMG from '../../../assets/images/icons/megaphone.png';
import React, { useState } from 'react';

interface IProps {
  top?: boolean;
}

const FutureUpdateNotification = ({ top }: IProps) => {
  const [isClosedFutureUpdateNotification, setIsClosedFutureUpdateNotification] =
    useState(!!localStorage.getItem('future-update-notification'));

  const handleCloseFutureUpdate = () => {
    localStorage.setItem('future-update-notification', JSON.stringify(true));
    setIsClosedFutureUpdateNotification(true);
  };

  // if (isClosedFutureUpdateNotification) {
  //   return null;
  // }

  return null;

  // return (
  //   <div className={`b-siteHeader__futureUpdate ${top ? '-top' : ''}`}>
  //     <button
  //       className="b-modal__closeBtn"
  //       onClick={handleCloseFutureUpdate}
  //       aria-label="Close Modal"
  //     />
  //     <p>
  //       <img
  //         style={{
  //           width: 24,
  //           height: 24,
  //           marginRight: 4,
  //         }}
  //         src={NOTIFICATION_IMG}
  //         alt="NOTIFICATION_IMG"
  //       />
  //       Dear Students, we are pleased to inform you about an upcoming intermediate update.
  //       Passmasters will be down on 04/03/2024 (Tue) from 2AM to 5AM EST.
  //     </p>
  //   </div>
  // );
};

export default FutureUpdateNotification;
