import React, { useState } from 'react';
import Draggable from 'react-draggable';
import { Dialog } from '@mui/material';

interface IProps {
  children: JSX.Element;
  title: string;
  onClose: () => void;
}

function DragComponent({ children, title, onClose }: IProps) {
  const [activeDrags, setActiveDrags] = useState(0);

  const onStart = () => {
    setActiveDrags(activeDrags + 1);
  };

  const onStop = () => {
    setActiveDrags(activeDrags - 1);
  };

  const dragHandlers = { onStart: onStart, onStop: onStop };

  return (
    <Dialog
      className={'b-dnd__dialog'}
      PaperProps={{
        style: {
          minWidth: '100vw',
          minHeight: '100vh',
        },
      }}
      onClick={onClose}
      open={true}
    >
      <Draggable
        handle={'.b-dnd__title'}
        positionOffset={{ x: '-50%', y: '-50%' }}
        {...dragHandlers}
      >
        <div
          className={`b-dnd`}
          onClick={(e: any) => e.stopPropagation()}
          style={{ position: 'absolute', top: '50%', left: '50%' }}
        >
          <div className="b-dnd__wrapper">
            <button
              onClick={onClose}
              className="b-dnd__closeBtn"
              aria-label="Close Modal"
            />
            <div className="b-dnd__title">{title}</div>
            <div className="b-dnd__text">{children}</div>
          </div>
        </div>
      </Draggable>
    </Dialog>
  );
}

export default DragComponent;
