import React, { FC, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as EyeOff } from '../../../assets/images/icons/eye_off_ic_24x24.svg';
import { ReactComponent as EyeOn } from '../../../assets/images/icons/eye_on_ic_24x24.svg';
import { FieldError } from 'react-hook-form';

interface IProps {
  onChange: (e: any) => void;
  name: string;
  error?: FieldError;
  value: string;
}

const PasswordInputSignUp: FC<IProps> = ({ error, onChange, name, value }) => {
  let [showPw, setShowPw] = useState(false);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <div className="b-formGroup -input-label">
      <div className="b-formGroup__label">Your Password</div>
      <TextField
        name={name}
        className="b-formGroup__input -password"
        placeholder="Must be a minimum of 8 characters "
        type={showPw ? 'text' : 'password'}
        error={Boolean(error)}
        value={value}
        onChange={onChange}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPw(!showPw)}
                onMouseDown={handleMouseDownPassword}
              >
                {showPw ? <EyeOff /> : <EyeOn />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {error ? <div className="b-authInput__errorText">{error.message}</div> : null}
    </div>
  );
};

export default PasswordInputSignUp;
