import React, { useState, forwardRef } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import pwd_ic from '../../../assets/images/icons/pwd_ic_24x24.svg';
import IconButton from '@mui/material/IconButton';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import { ChangeHandler, FieldError } from 'react-hook-form';

interface IProps {
  onChange: ChangeHandler;
  name: string;
  error?: FieldError;
  ref: React.Ref<HTMLInputElement>;
  placeholder?: string;
}

const PasswordInputSignIn = forwardRef<HTMLInputElement, IProps>(
  ({ onChange, name, error, placeholder }, ref) => {
    let [showPw, setShowPw] = useState(false);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };

    return (
      <div className="b-authInput">
        <TextField
          className="b-authInput__input -password"
          placeholder={placeholder}
          type={showPw ? 'text' : 'password'}
          onChange={onChange}
          name={name}
          error={Boolean(error)}
          ref={ref}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img width={24} height={24} src={pwd_ic} alt="PWD" />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPw(!showPw)}
                  onMouseDown={handleMouseDownPassword}
                >
                  {showPw ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {error ? <div className="b-authInput__errorText">{error.message}</div> : null}
      </div>
    );
  },
);

PasswordInputSignIn.defaultProps = {
  placeholder: 'Password',
};

export default PasswordInputSignIn;
