import React, { FC } from 'react';
import { getFormattedDateToUS } from '../../../../helpers/commonHelpers';
import mp3ReleaseImg from '../../../../assets/images/notifications/mp3_release_img.webp';

interface IProps {
  date_created: string;
}

const OtgMp3Release: FC<IProps> = ({ date_created }) => {
  return (
    <div className="b-notification__releaseWrappper">
      <p className="b-notification__date overflow-hidden text-left">
        {getFormattedDateToUS(date_created)}
      </p>
      <p className="b-notification__notification">
        We have added some <b>Extra Credit</b> for you!
        <br />
        Based on a student request we have developed course specific playlists -{' '}
        <b>On the Go Audio!</b> These are playlists of the Essential Concepts that you
        must know to <b>MASTER</b> the concepts and <b>PASS</b> your exam on the first
        attempt. So the next time you are working out, walking the dog, cooking dinner, or
        just tidying up around the house press play on your course playlist, it will take
        you through all of the Essential Concepts in your course!
        <br />
        <b>Extra Credit</b> is found from your Homeroom screen, under exam scores.
        <br />
        <b>HAPPY STUDIES!</b>
      </p>
      <img
        className="b-notification__image"
        src={mp3ReleaseImg}
        alt="mp3 release image"
      />
    </div>
  );
};

export default OtgMp3Release;
