import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { setTheme } from '../../../store/user/userSlice';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from 'react';

const ThemeSwitchButton = () => {
  const themeFromRedux = useAppSelector(state => state.user.theme);
  const dispatch = useAppDispatch();
  const isDarkTheme = themeFromRedux === 'dark';
  const darkModeClass = isDarkTheme ? '-dark' : '';
  const [showTooltip, setShowTooltip] = useState(false);

  useEffect(() => {
    const themeTooltipShown = localStorage.getItem('themeTooltipShown');
    if (!themeTooltipShown) {
      setTimeout(() => {
        setShowTooltip(true);
      }, 400);
    }
  }, []);

  const closeTooltip = () => {
    localStorage.setItem('themeTooltipShown', 'true');
    setShowTooltip(false);
  };

  return (
    <div className="b-themeSwitchBtnContainer">
      <button
        className={`b-themeSwitchBtn ${darkModeClass}`}
        onClick={() => {
          dispatch(setTheme(isDarkTheme ? 'light' : 'dark'));
        }}
      >
        <div className={`b-themeSwitchBtn__iconWrapper ${darkModeClass}`}>
          <div className={`b-themeSwitchBtn__icon ${darkModeClass}`} />
        </div>
      </button>
      <div className={`b-themeSwitchBtnTooltip ${showTooltip ? '-visible' : ''}`}>
        For more enjoyable use we have added a dark mode for you
        <div onClick={closeTooltip} className="b-themeSwitchBtnTooltip__close">
          <CloseIcon />
        </div>
      </div>
    </div>
  );
};

export default ThemeSwitchButton;
