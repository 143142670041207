import { FC } from 'react';

import { toHoursMinutesAndSeconds } from '../../../helpers/commonHelpers';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ExamScoreSingleTableModel } from '../../../models';

function createData(
  key: number,
  exam_quiz: string,
  result: string,
  scorePercent: number | string,
  passing_score: number,
  startedDate: string,
  startedTime: string,
  finishDate: string,
  finishTime: string,
  timeSpent: string,
  course_name: string,
) {
  return {
    key,
    exam_quiz,
    result,
    scorePercent,
    passing_score,
    startedDate,
    startedTime,
    finishDate,
    finishTime,
    timeSpent,
    course_name,
  };
}

const ExamScoreSingleTable: FC<ExamScoreSingleTableModel> = ({
  exam_name,
  start_date,
  finish_date,
  passing_score,
  time_spent,
  grade_score_green,
  grade_score_red,
  grade_score_yellow,
  score,
  course_name,
  examProgress,
}: ExamScoreSingleTableModel) => {
  const formatMinutesToHours = toHoursMinutesAndSeconds(time_spent);
  const status = () => {
    if (Number(score) >= grade_score_green) {
      return 'passed';
    }
    if (Number(score) > grade_score_red && Number(score) <= grade_score_yellow) {
      return 'almost done';
    }
    if (Number(score) <= grade_score_red) {
      return 'failed';
    }
    return '';
  };

  const statusRow = status();

  const rows = [
    createData(
      1,
      exam_name,
      statusRow,
      score,
      passing_score,
      start_date.slice(0, 10),
      start_date.slice(11, 19),
      finish_date.slice(0, 10),
      finish_date.slice(11, 19),
      formatMinutesToHours,
      course_name,
    ),
  ];
  return (
    <div className="b-examScoreTable -single">
      <TableContainer style={{ minWidth: 800 }}>
        <Table aria-label="exam-score table">
          <TableHead>
            <TableRow>
              <TableCell>Course</TableCell>
              <TableCell>Exam</TableCell>
              <TableCell>Result</TableCell>
              <TableCell>Score</TableCell>
              <TableCell>Passing Score</TableCell>
              <TableCell>Started</TableCell>
              <TableCell>Finish</TableCell>
              <TableCell>Time Spent</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => {
              return (
                <TableRow key={row.key}>
                  <TableCell>{row.course_name}</TableCell>
                  <TableCell>{row.exam_quiz}</TableCell>
                  <TableCell>
                    {row.result === 'passed' ? (
                      <span className="b-examScoreTable__result -done">Passed</span>
                    ) : null}
                    {row.result === 'almost done' ? (
                      <span className="b-examScoreTable__result -almost-done">
                        Almost Done
                      </span>
                    ) : null}
                    {row.result === 'failed' ? (
                      <span className="b-examScoreTable__result -failed">Failed</span>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    <span
                      className={`b-examScoreTable__score 
                          ${row.result === 'passed' ? '-passed' : ''}
                          ${row.result === 'almost done' ? '-almost' : ''}
                          ${row.result === 'failed' ? '-failed' : ''}`}
                    >
                      {examProgress.passed_q || 'No Data'}/
                      {examProgress.total_q || 'No Data'} (
                      {Number(examProgress.score || 0).toFixed(2)}%)
                    </span>
                  </TableCell>
                  <TableCell>{row.passing_score}%</TableCell>
                  <TableCell>
                    {row.startedDate}
                    <br />
                    <span className="b-examScoreTable__time">{row.startedTime}</span>
                  </TableCell>
                  <TableCell>
                    {row.finishDate}
                    <br />
                    <span className="b-examScoreTable__time">{row.finishTime}</span>
                  </TableCell>
                  <TableCell>{row.timeSpent}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default ExamScoreSingleTable;
