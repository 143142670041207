import React, { FC, useState } from 'react';
import { ROUTES } from '../../../router/routes';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../hooks/global';
import { signOut } from '../../../store/user/userThunk';
import { resetForm } from '../../../store/user/userSlice';

import logo from '../../../assets/images/siteLogo/logo.svg';
import logoWhite from '../../../assets/images/siteLogo/logo_280x40_dark.svg';
import profile_ill from '../../../assets/images/dashboard/human-default-avatar.svg';
import profile_ill_light from '../../../assets/images/dashboard/human-default-avatar-light.svg';
import { ReactComponent as Hamburger } from '../../../assets/images/icons/menu_icon_24x24.svg';
import { ReactComponent as HamburgerClose } from '../../../assets/images/icons/close_icon_24x24.svg';
import { clearCoursesState } from '../../../store/courses/coursesThunk';
import { WP_DOMAIN_URI } from '../../../constants/api';
import FutureUpdateNotification from '../FutureUpdateNotification/FutureUpdateNotification';
import useIsDarkTheme from '../../../hooks/use-is-dark-theme';
import ThemeSwitchButton from '../ThemeSwitchButton/ThemeSwitchButton';
import { Box, Stack } from '@mui/material';
import UserNotification from '../UserNotification/UserNotification';

const HeaderView: FC = () => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isDarkTheme = useIsDarkTheme();
  const isAuthorized = useAppSelector(state => state.user.access_token);
  const isSignUp = location.pathname.match('sign-up');
  const { first_name, last_name } = useAppSelector(
    state => state.user.user || { first_name: '', last_name: '' },
  );

  const handleLogOut = () => {
    dispatch(signOut()).then(() => dispatch(resetForm()));
    dispatch(clearCoursesState()).then();
  };

  const renderNav = () => {
    if (isAuthorized) {
      return (
        <div className={`l-mainNavigation -authMenu ${isOpen ? 'state_open' : ''}`}>
          <ul className={`b-mainNavigation ${isOpen ? 'state_open' : ''}`}>
            <li className="b-mainNavigation__item">
              <RouterLink className="b-mainNavigation__link" to={ROUTES.MY_PROFILE}>
                My PROFILE
              </RouterLink>
            </li>
            <li className="b-mainNavigation__item">
              <a className="b-mainNavigation__link" href={`${WP_DOMAIN_URI}/contact-us/`}>
                Contact Us
              </a>
            </li>
          </ul>

          <div className="b-authMenu">
            <button className="b-authMenu__signIn" onClick={handleLogOut}>
              Logout
            </button>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`l-mainNavigation ${isOpen ? 'state_open' : ''}`}>
          <ul className={`b-mainNavigation ${isOpen ? 'state_open' : ''}`}>
            <li className="b-mainNavigation__item -has-children">
              <div className="b-mainNavigation__link">
                Available Courses
                <div className="b-mainNavigation__submenu -depth_1 sub-menu">
                  <div className="b-mainNavigation__item menu-item -depth_2">
                    <a
                      href={`${WP_DOMAIN_URI}/insurance-exam-prep/`}
                      className="b-mainNavigation__link -depth_2"
                    >
                      Insurance Exam Prep
                    </a>
                  </div>
                  <div className="b-mainNavigation__item menu-item -depth_2">
                    <a
                      href={`${WP_DOMAIN_URI}/securities-exam-prep/`}
                      className="b-mainNavigation__link -depth_2"
                    >
                      Securities Exam Prep
                    </a>
                  </div>
                </div>
              </div>
            </li>
            <li className="b-mainNavigation__item">
              <RouterLink
                className="b-mainNavigation__link"
                to={ROUTES.SELECT_FREE_PRACTICE_EXAM}
              >
                Free Practice Exams
              </RouterLink>
            </li>
            <li className="b-mainNavigation__item">
              <a className="b-mainNavigation__link" href={`${WP_DOMAIN_URI}/contact-us/`}>
                Contact Us
              </a>
            </li>
          </ul>
          <div className="b-authMenu">
            <RouterLink className="b-authMenu__signIn" to={ROUTES.SIGN_IN}>
              Sign In
            </RouterLink>
            {!isSignUp ? (
              <RouterLink className="b-authMenu__signUp" to={ROUTES.SIGN_UP_STEP_1}>
                <span className="b-authControl__btnText">Sign Up</span>
              </RouterLink>
            ) : null}
          </div>
        </div>
      );
    }
  };

  const renderRight = () => {
    if (isAuthorized) {
      return (
        <div className="b-authControl__btnWrapper">
          <a className="b-authControl__contactLink" href={`${WP_DOMAIN_URI}/contact-us/`}>
            Contact Us
          </a>
          <UserNotification />
          <Box sx={{ marginRight: { lg: '20px' } }}>
            <ThemeSwitchButton />
          </Box>

          <div className="b-authControl__profileLink">
            <div className="b-authControl__profileImgWrapper">
              <img
                className="b-authControl__profileImg"
                src={isDarkTheme ? profile_ill_light : profile_ill}
                alt="Profile"
              />
            </div>
            <div className="b-authControl__profileInfoWrapper">
              <p className="b-authControl__profileName">
                {first_name} {last_name}
              </p>
              <p className="b-authControl__profileStatus">Superstar</p>
            </div>

            <div className="b-profileMenu">
              <div className="b-profileMenu__list">
                <RouterLink className="b-profileMenu__item" to={ROUTES.MY_PROFILE}>
                  My Profile
                </RouterLink>
                <button
                  className="b-profileMenu__item"
                  type="submit"
                  onClick={handleLogOut}
                >
                  Logout
                </button>
              </div>
            </div>
          </div>

          <button className="b-hamburger" type="button" onClick={() => setOpen(!isOpen)}>
            {!isOpen ? (
              <Hamburger className="b-hamburger__icon" />
            ) : (
              <HamburgerClose className="b-hamburger__icon" />
            )}
            <span className="b-hamburger__text">{!isOpen ? 'MENU' : 'Close'}</span>
          </button>
        </div>
      );
    } else {
      return (
        <div className="b-authControl__btnWrapper">
          <RouterLink
            className={`b-authControl__signIn ${!isSignUp ? '-loggedOut' : ''}`}
            to={ROUTES.SIGN_IN}
          >
            Sign In
          </RouterLink>
          {!isSignUp ? (
            <RouterLink className="b-authControl__signUp" to={ROUTES.SIGN_UP_STEP_1}>
              <span className="b-authControl__btnText">Sign Up</span>
            </RouterLink>
          ) : null}
          <button className="b-hamburger" type="button" onClick={() => setOpen(!isOpen)}>
            {!isOpen ? (
              <Hamburger className="b-hamburger__icon" />
            ) : (
              <HamburgerClose className="b-hamburger__icon" />
            )}
            <span className="b-hamburger__text">{!isOpen ? 'MENU' : 'Close'}</span>
          </button>
        </div>
      );
    }
  };

  return (
    <div className="l-siteHeader">
      <header className="b-siteHeader">
        <div className="b-siteHeader__wrapperInner">
          <div className="l-siteLogo">
            <div className="b-siteLogo">
              <RouterLink className="b-siteLogo__link" to="/">
                <img
                  className="b-siteLogo__img"
                  width={280}
                  height={'100%'}
                  src={isDarkTheme ? logoWhite : logo}
                  alt="Logo"
                />
              </RouterLink>
            </div>
          </div>
          {renderNav()}
          <Stack
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            marginLeft="auto"
          >
            {!isAuthorized ? (
              <Box sx={{ marginRight: { lg: '25px', xs: '10px' } }}>
                <ThemeSwitchButton />
              </Box>
            ) : null}
            <div className="b-authControl">{renderRight()}</div>
          </Stack>
        </div>
        <FutureUpdateNotification />
      </header>
    </div>
  );
};

export default HeaderView;
