import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MAX_NUMBER_OF_QUESTIONS_FOR_EXAM } from '../../pages/QToolCreateCustomExam/parts/CreateExamStep2';
import { useEffect, useState } from 'react';

const SX_STYLES = {
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      border: 'none',
    },
    '&:hover fieldset': {
      border: 'none',
    },
    '&.Mui-focused fieldset': {
      border: 'none',
    },
    '& input[type=number]': {
      MozAppearance: 'textfield',
    },
    '& input[type=number]::-webkit-outer-spin-button, & input[type=number]::-webkit-inner-spin-button':
      {
        WebkitAppearance: 'none',
        margin: 0,
      },
    '& input': {
      color: 'var(--text-color)',
    },
    '&.Mui-disabled input': {
      opacity: 0.5,
      WebkitTextFillColor: 'var(--text-color) !important',
    },
  },
};

interface IProps {
  handleChangeOfQuestionsNumber: (val: string) => void;
  totalEstimate: string;
  totalQuestions: number;
  numberOfPossibleQuestions: number;
  isDisabled: boolean;
  initialNumberOfQuestions: number;
  currentNumberOfQuestions?: number;
  isReadOnly?: boolean;
}

const NumberOfQuestions = ({
  handleChangeOfQuestionsNumber,
  totalEstimate,
  totalQuestions,
  numberOfPossibleQuestions,
  isDisabled,
  initialNumberOfQuestions,
  currentNumberOfQuestions,
  isReadOnly = false,
}: IProps) => {
  const [numberOfQuestions, setNumberOfQuestions] = useState<number>(
    initialNumberOfQuestions,
  );

  useEffect(() => {
    setNumberOfQuestions(
      currentNumberOfQuestions && currentNumberOfQuestions > 0
        ? currentNumberOfQuestions
        : 0,
    );
  }, [currentNumberOfQuestions]);

  useEffect(() => {
    handleChangeOfQuestionsNumber(String(numberOfQuestions));
  }, [numberOfQuestions]);

  useEffect(() => {
    setNumberOfQuestions(initialNumberOfQuestions);
  }, [initialNumberOfQuestions]);

  const handleLessBtn = () => {
    if (Number(numberOfQuestions) > 0) {
      setNumberOfQuestions(numberOfQuestions - 1);
    }
  };

  const handleMoreBtn = () => {
    if (
      Number(numberOfQuestions) < MAX_NUMBER_OF_QUESTIONS_FOR_EXAM &&
      Number(numberOfQuestions) < numberOfPossibleQuestions
    ) {
      setNumberOfQuestions(numberOfQuestions + 1);
    }
  };

  const onChange = (val: string) => {
    const numberVal = Number(val);
    if (numberVal < 0) return;
    const maxQuestionsNumber = Math.min(
      numberOfPossibleQuestions,
      MAX_NUMBER_OF_QUESTIONS_FOR_EXAM,
    );
    if (numberVal >= maxQuestionsNumber) {
      setNumberOfQuestions(maxQuestionsNumber);
      return;
    }
    setNumberOfQuestions(numberVal);
  };

  return (
    <>
      <Typography variant="h6" fontWeight={600} className="mt-auto">
        Number of Questions
      </Typography>
      <div className="number-questions">
        <TextField
          disabled={isDisabled || isReadOnly}
          variant="outlined"
          sx={SX_STYLES}
          type="number"
          value={numberOfQuestions}
          onChange={e => onChange(e.target.value)}
        />
        <div className="btns">
          <Button disabled={isDisabled || isReadOnly} onClick={handleLessBtn}>
            <span className={`b-btn__text ${isReadOnly ? 'opacity50' : ''}`}>-</span>
          </Button>
          <div className="divider" />
          <Button disabled={isDisabled || isReadOnly} onClick={handleMoreBtn}>
            <span className={`b-btn__text ${isReadOnly ? 'opacity50' : ''}`}>+</span>
          </Button>
        </div>
      </div>
      <div className="total-estimate">
        <p className="mb-1">We believe that 130 questions is plenty for any one exam!</p>
        <div>
          Estimated time: <b>{totalEstimate}</b>
        </div>
        <div>
          Total questions: <b>{totalQuestions}</b>
        </div>
      </div>
    </>
  );
};

export default NumberOfQuestions;
