import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import { KeyboardArrowDown } from '@mui/icons-material';
import { IdNameModel } from '../../../models/global';

const ADDITIONAL_STYLES = {
  maxWidth: '90px',
  maxHeight: '32px',
  marginLeft: 'auto',
  marginRight: '10px',
  borderRadius: '4px',
  paddingRight: '0',
  borderColor: 'var(--border-color4) !important',
  '.MuiSelect-select': {
    padding: '0',
  },
};

interface IProps {
  handleChangeItem: (event: SelectChangeEvent<number | 'Select Item'>) => void;
  selectedItemId: number | null;
  items: IdNameModel[];
  itemsName?: string;
  withAdditionalStyles?: boolean;
}

const Selector = ({
  handleChangeItem,
  selectedItemId,
  items,
  itemsName,
  withAdditionalStyles,
}: IProps) => {
  return (
    <>
      {itemsName && <p className="b-qtool__courseSelect-title">{itemsName}:</p>}
      <Select
        className="b-formGroup__select b-qtool__courseSelect-select"
        sx={withAdditionalStyles ? ADDITIONAL_STYLES : undefined}
        variant="standard"
        disableUnderline={true}
        onChange={handleChangeItem}
        value={selectedItemId ?? 'Select Item'}
        IconComponent={() => <KeyboardArrowDown />}
        renderValue={
          selectedItemId
            ? undefined
            : () => <span style={{ color: 'gray' }}>Select Item</span>
        }
      >
        {(items || []).map(item => (
          <MenuItem key={`co-${item.id}`} value={item.id}>
            {item.name}
          </MenuItem>
        ))}
      </Select>
    </>
  );
};

export default Selector;
