import { FC } from 'react';

type TitleProps = {
  text: string;
};

const Title: FC<TitleProps> = ({ text }: TitleProps) => {
  return <h1 className="b-sighUp__title">{text}</h1>;
};

export default Title;
