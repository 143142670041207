import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import checkbox_ic from '../../../assets/images/icons/checkbox.svg';
import checkbox_checked_ic from '../../../assets/images/icons/checkbox_checked.svg';
import Link from '@mui/material/Link';

interface ILayoutProps {
  checkedPolicy: boolean;
  setCheckedPolicy: React.Dispatch<React.SetStateAction<boolean>>;
  type: 'sign-up' | 'renewal' | 'add-course';
}

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
};

const RefundPolicy: React.FC<ILayoutProps> = ({
  checkedPolicy,
  setCheckedPolicy,
  type,
}: ILayoutProps) => {
  const [modal, setModal] = useState<boolean>(false);

  const onCloseModal = () => {
    setModal(false);
  };

  const handleOpenModal = (e: any) => {
    e.preventDefault();
    setModal(true);
  };

  const getText = () => {
    if (type === 'sign-up') {
      return `Course purchases are only eligible for a refund within 30 days of
                  purchase, and only if the course has NOT been accessed. On any refund
                  issued, a $20 administrative fee will apply. By clicking this box you
                  are agreeing to our return/refund policy.`;
    } else if (type === 'renewal') {
      return 'Course renewal fees are non-refundable.';
    } else if (type === 'add-course') {
      return 'Course purchases are only eligible for a refund within 30 days of purchase, and only if the course has NOT been accessed. On any refund issued, a $20 administrative fee will apply. By clicking this box you are agreeing to our return/refund policy.';
    } else {
      return '';
    }
  };

  return (
    <>
      <FormControlLabel
        className="b-authInput__checkbox"
        control={
          <Checkbox
            sx={{
              color: 'var(--text-color)',
              transform: 'scale(1.3)',
            }}
            checked={checkedPolicy}
            onChange={e => setCheckedPolicy(e.target.checked)}
          />
        }
        label={
          <div>
            <Link href={'#'} onClick={handleOpenModal}>
              Refund policy
            </Link>
          </div>
        }
      />
      {modal ? (
        <Modal
          open={modal}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle} className="b-modal">
            <div className="b-modal__wrapper">
              <button
                className="b-modal__closeBtn"
                onClick={() => setModal(false)}
                aria-label="Close Modal"
              />
              <div className="b-modal__title">Returns/Refunds</div>
              <div className="b-modal__text">
                <p>{getText()}</p>
              </div>
            </div>
          </Box>
        </Modal>
      ) : null}
    </>
  );
};

export default RefundPolicy;
