import React, { FC, Fragment, useState } from 'react';

import correct_ic from '../../../assets/images/score/correct_ic.svg';
import missed_ic from '../../../assets/images/score/missed_ic.svg';
import { isEmptyField, sanitizedHTML } from '../../../helpers/commonHelpers';
import { QuestionScoreStepModel } from '../../../models';
import { DOMAIN_URI } from '../../../constants/api';
import QuestionWithAnswers from '../QuestionWithAnswers/QuestionWithAnswers';

interface Props {
  title: string;
  totalValue: number;
  correctValue: number;
  correctPercentValue: number;
  missedValue: number;
  questionsList: QuestionScoreStepModel[];
  isHideAnswers?: boolean;
  isPrintView?: boolean;
}

const ExamScoreStepInner: FC<Props> = ({
  title,
  totalValue,
  correctValue,
  correctPercentValue,
  missedValue,
  questionsList,
  isHideAnswers,
  isPrintView = false,
}) => {
  const [isTabContent, setTabContent] = useState(0);
  const [isShowMore, setShowMore] = useState(false);

  return (
    <div className={`b-examScoreStepInner`}>
      <div className="b-examScoreStepInner__topWrapper">
        <h3 className="b-examScoreStepInner__title">&gt;&nbsp;{title}</h3>

        <ul className="b-examScoreStepInner__infoList">
          <li className="b-examScoreStepInner__infoItem">
            <span className="b-examScoreStepInner__infoValue -blue">
              {totalValue}&nbsp;
            </span>
            Total&nbsp;Questions
          </li>
          <li className="b-examScoreStepInner__infoItem">
            <span className="b-examScoreStepInner__infoValue -green">
              {correctValue}&nbsp;
            </span>
            Correct
            <span className="b-examScoreStepInner__infoValue -green">
              &nbsp;({correctPercentValue}%)
            </span>
          </li>
          <li className="b-examScoreStepInner__infoItem">
            <span className="b-examScoreStepInner__infoValue -red">
              {missedValue}&nbsp;
            </span>
            Missed
          </li>
        </ul>
      </div>

      {!isHideAnswers ? (
        <>
          <div className="b-examScoreStepInner__questionListWrapper state_open">
            <ul className="b-examScoreStepInner__questionList">
              {questionsList
                .sort(
                  (a: QuestionScoreStepModel, b: QuestionScoreStepModel) =>
                    a.position - b.position,
                )
                .map((question: QuestionScoreStepModel, idx: number) => (
                  <li
                    key={question.question_id}
                    className="b-examScoreStepInner__questionItem"
                  >
                    <button
                      className={`b-examScoreStepInner__questionBtn ${
                        question.status === 'yes' ? '-green' : '-red'
                      }  ${isTabContent === idx ? '-active' : ''}`}
                      type="button"
                      onClick={() => {
                        setTabContent(idx);
                        setShowMore(false);
                      }}
                    >
                      {idx + 1}
                    </button>
                  </li>
                ))}
            </ul>
          </div>

          {questionsList[isTabContent] ?
            <div className="b-examScoreStepInner__questionContentWrapper state_open">
              <div className="b-examScoreStepInner__questionContent">
                <div className="b-scoreQuestionInfo">
                  <div className="b-scoreQuestionInfo__questionCounterWrapper">
                    <div className="b-scoreQuestionInfo__bigCounter">
                      {questionsList[isTabContent].status === 'yes' ? (
                        <img
                          src={correct_ic}
                          alt="Correct"
                          className="b-scoreQuestionInfo__statusIcon"
                        />
                      ) : (
                        <img
                          src={missed_ic}
                          alt="Missed"
                          className="b-scoreQuestionInfo__statusIcon"
                        />
                      )}
                      Question {isTabContent + 1} of {questionsList.length}
                    </div>
                    <div className="b-scoreQuestionInfo__smallCounter">
                      {isTabContent + 1}/{questionsList.length}
                    </div>
                  </div>
                  <div className="b-scoreQuestionInfo__explanation">
                    Explanation
                    <div className="b-scoreQuestionInfo__id">
                      ID: {questionsList[isTabContent].question_id}
                    </div>
                  </div>
                  <div className="b-scoreQuestionInfo__descriptionWrapper">
                    <p
                      className="b-scoreQuestionInfo__description"
                      dangerouslySetInnerHTML={{
                        __html: sanitizedHTML(questionsList[isTabContent].question_text),
                      }}
                    />

                    <div className="b-scoreQuestionInfo__answer -correct">
                      CORRECT ANSWER:&nbsp;
                      <span>{questionsList[isTabContent].correct_answer}</span>
                    </div>
                    {questionsList[isTabContent].status === 'no' && (
                      <div className="b-scoreQuestionInfo__answer -failed">
                        YOUR ANSWER:&nbsp;
                        <span>{questionsList[isTabContent].user_answer}</span>
                      </div>
                    )}
                  </div>
                  {questionsList[isTabContent].audio_expl ||
                  !isEmptyField(questionsList[isTabContent].question_explanation) ? (
                    <div className="b-scoreQuestionInfo__audioWrapper">
                      <div className="b-scoreQuestionInfo__audioPlayerWrapper">
                        {questionsList[isTabContent].audio_expl ? (
                          <audio
                            className="b-courseOverview__explanationAudio"
                            controls
                            src={`${DOMAIN_URI}${questionsList[isTabContent].audio_expl}`}
                          >
                            Your browser does not support the
                            <code>audio</code> element.
                          </audio>
                        ) : null}
                      </div>
                      <p
                        className={`b-scoreQuestionInfo__audioText ${
                          !isShowMore ? '-less' : ''
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: sanitizedHTML(
                            questionsList[isTabContent].question_explanation,
                          ),
                        }}
                      />

                      {/*//TODO REFACTOR IT*/}
                      <a
                        className="mt-1 d-block"
                        style={{
                          fontWeight: 700,
                          textDecoration: 'none',
                          color: '#41cae7',
                        }}
                        onClick={e => {
                          e.preventDefault();
                          setShowMore(!isShowMore);
                        }}
                        href="#"
                      >
                        {isShowMore ? 'Show less' : ' Show more'}
                      </a>
                    </div>
                  ) : (
                    <strong className="pt-4 pb-4 pl-2">{`The explanation hasn't been added yet`}</strong>
                  )}
                </div>
              </div>
            </div>
            : null}
        </>
      ) : null}
      {isPrintView ? (
        <>
          {questionsList
            .sort(
              (a: QuestionScoreStepModel, b: QuestionScoreStepModel) =>
                a.position - b.position,
            )
            .map((question: QuestionScoreStepModel, index: number) => (
              <Fragment key={question.id}>
                <QuestionWithAnswers
                  question={question as any}
                  index={index}
                  totalQuestionsNumber={questionsList.length}
                />
              </Fragment>
            ))}
        </>
      ) : null}
    </div>
  );
};

export default ExamScoreStepInner;
