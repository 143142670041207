import React, { FC, useState } from 'react';

import ExamScoreStepInner from '../../common/ExamScoreStepInner/ExamScoreStepInner';

import SectionArrow from '@mui/icons-material/KeyboardArrowUp';
// import { ReactComponent as SectionArrow } from '../../../assets/images/icons/arrow_down_ic_16x16.svg';
import question_ic from '../../../assets/images/score/question_ic.svg';
import correct_ic from '../../../assets/images/score/correct_ic.svg';
import missed_ic from '../../../assets/images/score/missed_ic.svg';
import { getAvailableAudio } from '../../../store/courses/coursesThunk';
import { useAppDispatch } from '../../../hooks/global';
import { AudioAvailableStatusModel, QuestionScoreStepModel } from '../../../models';
import { KeyValueModel } from '../../../models/global';

interface Props {
  title: string;
  totalValue: number;
  correctValue: number;
  correctPercentValue: number;
  missedValue: number;
  questions?: KeyValueModel<QuestionScoreStepModel>;
  categoryId: number | string;
}

const ExamScoreStep: FC<Props> = ({
  title,
  totalValue,
  correctValue,
  correctPercentValue,
  missedValue,
  questions,
  categoryId,
}) => {
  const [isOpen, setOpen] = useState(false);
  const dispatch = useAppDispatch();
  const [availableAudio, setAvailableAudio] = useState<
    AudioAvailableStatusModel[] | null
  >(null);
  const [questionsList, setQuestionsList] = useState<QuestionScoreStepModel[]>(
    Object.values(questions || []),
  );

  const handleOpenStep = () => {
    setOpen(!isOpen);
    const element = document.getElementById(`examBlock-${categoryId}`);
    if (element && !isOpen) {
      setTimeout(() => {
        element.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 200);
    }

    if (!availableAudio) {
      const payload = {
        ids: questionsList.map(q => String(q.question_id)),
      };
      const tempQuestion = { ...questions };
      dispatch(getAvailableAudio(payload))
        .unwrap()
        .then(response => {
          response.audio_expl_details.map(audio => {
            if (tempQuestion[audio.id] && audio.expl) {
              tempQuestion[audio.id].audio_expl = audio.link;
            }
          });
          setAvailableAudio(response.audio_expl_details);
          setQuestionsList(Object.values(tempQuestion));
        });
    }
  };

  return (
    <div
      className={`b-examScoreStep 
          ${isOpen ? 'state_open' : ''}`}
    >
      <button
        className="b-examScoreStep__header"
        id={`examBlock-${categoryId}`}
        onClick={handleOpenStep}
      >
        <span className="b-examScoreStep__title">{title}</span>
        <SectionArrow className="b-examScoreStep__arrow" />

        <span className="b-examScoreStep__listInfo">
          <span className="b-examScoreStep__listItem">
            <img src={question_ic} alt="Question" className="b-examScoreStep__listIcon" />
            <span className="b-examScoreStep__listValue">{totalValue}</span>
            <span className="b-examScoreStep__listText">Total Questions</span>
          </span>
          <span className="b-examScoreStep__listItem">
            <img src={correct_ic} alt="Correct" className="b-examScoreStep__listIcon" />
            <span className="b-examScoreStep__listValue">{correctValue}</span>
            <span className="b-examScoreStep__listText">
              Correct ({correctPercentValue}%)
            </span>
          </span>
          <span className="b-examScoreStep__listItem">
            <img src={missed_ic} alt="Missed" className="b-examScoreStep__listIcon" />
            <span className="b-examScoreStep__listValue">{missedValue}</span>
            <span className="b-examScoreStep__listText">Missed</span>
          </span>
        </span>
      </button>

      <div className={`b-examScoreStep__contentWrapper ${isOpen ? 'state_open' : ''}`}>
        <div className="b-examScoreStep__content">
          <ExamScoreStepInner
            title={title}
            totalValue={totalValue}
            correctValue={correctValue}
            correctPercentValue={correctPercentValue}
            missedValue={missedValue}
            questionsList={questionsList}
          />
        </div>
      </div>
    </div>
  );
};

export default ExamScoreStep;
