import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useAppSelector } from '../../../hooks/global';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  padding: '27px 10px',
  width: '100%',
  maxWidth: '600px',
  height: '177px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderRadius: '24px',
};

const ModalUpdateInfo = () => {
  const isCourseUpdated = useAppSelector(
    state => state.courses.currentCourse?.course_updated,
  );
  const [open, setOpen] = useState(isCourseUpdated || false);
  const handleOpenClose = () => setOpen(!open);

  return (
    <>
      <Modal
        open={open}
        onClose={handleOpenClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" align="center" component="h2">
            The course assignments have been modified/updated.
          </Typography>
          <Typography align="center">
            <Button
              className="b-courseTopInfo__btn b-btn -blue -smallSize"
              onClick={handleOpenClose}
              style={{ maxWidth: 200 }}
            >
              OK
            </Button>
          </Typography>
        </Box>
      </Modal>
    </>
  );
};

export default ModalUpdateInfo;
