import React, { FC } from 'react';
import logo from '../../../assets/images/siteLogo/logo.svg';
import logoDark from '../../../assets/images/siteLogo/logo_280x40_dark.svg';
import logo_gs from '../../../assets/images/siteLogo/logo-gs.svg';
import useIsDarkTheme from '../../../hooks/use-is-dark-theme';

const FooterView: FC = () => {
  const year = new Date().getFullYear();
  const isDarkTheme = useIsDarkTheme();

  return (
    <div className="l-siteFooter">
      <footer className="b-siteFooter">
        <div className="b-siteCopyright">
          © 2017-{year} PassMasters LLC. <br /> All Rights Reserved.
        </div>

        <ul className="b-socialList">
          <li className="b-socialList__item">
            <a
              className="b-socialList__link"
              href="https://www.youtube.com/channel/UCu9jZXMCrMbTivZoUriQjqA"
              target="_blank"
              aria-label=""
              rel="noreferrer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21.3304 7.42516C21.2296 6.58444 20.4374 5.77828 19.6084 5.67028C14.5535 5.0422 9.44202 5.0422 4.38882 5.67028C3.55914 5.77804 2.7669 6.58444 2.6661 7.42516C2.30922 10.5063 2.30922 13.4938 2.6661 16.5742C2.7669 17.4149 3.55914 18.2218 4.38882 18.3291C9.44202 18.9572 14.5538 18.9572 19.6084 18.3291C20.4374 18.222 21.2296 17.4149 21.3304 16.5742C21.6873 13.494 21.6873 10.5063 21.3304 7.42516ZM10.3982 15.2002V8.80012L15.1982 12.0003L10.3982 15.2002Z"
                  fill="#727272"
                />
              </svg>
            </a>
          </li>
          <li className="b-socialList__item">
            <a
              className="b-socialList__link"
              href="https://www.instagram.com/pass_masters/"
              target="_blank"
              aria-label=""
              rel="noreferrer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16.7984 2.39844H7.19844C4.55868 2.39844 2.39844 4.55868 2.39844 7.19844V16.7989C2.39844 19.438 4.55868 21.5989 7.19844 21.5989H16.7984C19.4382 21.5989 21.5984 19.438 21.5984 16.7989V7.19844C21.5984 4.55868 19.4382 2.39844 16.7984 2.39844ZM19.9984 16.7989C19.9984 18.5629 18.5634 19.9988 16.7984 19.9988H7.19844C5.4342 19.9988 3.99852 18.5629 3.99852 16.7989V7.19844C3.99852 5.43396 5.4342 3.99852 7.19844 3.99852H16.7984C18.5634 3.99852 19.9984 5.43396 19.9984 7.19844V16.7989Z"
                  fill="#727272"
                />
                <path
                  d="M17.2 8.00156C17.8627 8.00156 18.4 7.4643 18.4 6.80156C18.4 6.13882 17.8627 5.60156 17.2 5.60156C16.5373 5.60156 16 6.13882 16 6.80156C16 7.4643 16.5373 8.00156 17.2 8.00156Z"
                  fill="#727272"
                />
                <path
                  d="M11.9992 7.19922C9.3477 7.19922 7.19922 9.34794 7.19922 11.9992C7.19922 14.6495 9.3477 16.7997 11.9992 16.7997C14.65 16.7997 16.7992 14.6495 16.7992 11.9992C16.7992 9.34794 14.65 7.19922 11.9992 7.19922ZM11.9992 15.1996C10.2321 15.1996 8.7993 13.7668 8.7993 11.9992C8.7993 10.2316 10.2321 8.7993 11.9992 8.7993C13.7663 8.7993 15.1991 10.2316 15.1991 11.9992C15.1991 13.7668 13.7663 15.1996 11.9992 15.1996Z"
                  fill="#727272"
                />
              </svg>
            </a>
          </li>
          <li className="b-socialList__item">
            <a
              className="b-socialList__link"
              href="https://www.facebook.com/passmastersexamprep"
              target="_blank"
              aria-label=""
              rel="noreferrer"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5648 1.00458L14.7875 1C11.6674 1 9.65099 3.12509 9.65099 6.41423V8.91055H6.85857C6.61728 8.91055 6.42188 9.1115 6.42188 9.35937V12.9763C6.42188 13.2241 6.6175 13.4249 6.85857 13.4249H9.65099V22.5514C9.65099 22.7993 9.84639 23 10.0877 23H13.731C13.9723 23 14.1677 22.799 14.1677 22.5514V13.4249H17.4327C17.674 13.4249 17.8694 13.2241 17.8694 12.9763L17.8707 9.35937C17.8707 9.24036 17.8246 9.12638 17.7428 9.04215C17.661 8.95793 17.5496 8.91055 17.4338 8.91055H14.1677V6.79439C14.1677 5.77727 14.4036 5.26094 15.6935 5.26094L17.5643 5.26025C17.8054 5.26025 18.0008 5.0593 18.0008 4.81166V1.45317C18.0008 1.20576 17.8056 1.00504 17.5648 1.00458Z"
                  fill="#727272"
                />
              </svg>
            </a>
          </li>
        </ul>

        <a className="b-siteFooter__logo" href="#">
          <img
            className="b-siteFooter__logoImg -hover"
            src={isDarkTheme ? logoDark : logo}
            alt="Logo"
          />

          <img className="b-siteFooter__logoImg" src={logo_gs} alt="Logo" />
        </a>
      </footer>
    </div>
  );
};

export default FooterView;
