import React, { FC, useState } from 'react';

import ExamScoreTable from '../../common/ExamScoreTable/ExamScoreTable';

import SectionArrow from '@mui/icons-material/KeyboardArrowDown';

// import { ReactComponent as SectionArrow } from '../../../assets/images/icons/arrow_down_ic_16x16.svg';
import { ExamModel } from '../../../models';

import attempts_ic from '../../../assets/images/score/attempts_ic_20x20.svg';
import passed_ic from '../../../assets/images/score/passed_ic_20x20.svg';
import failed_ic from '../../../assets/images/score/failed_ic_20x20.svg';
import almost_done_ic from '../../../assets/images/score/almost_done_ic_20x20.svg';

interface Props {
  title: string;
  attemptsValue: number;
  passedValue: number;
  failedValue: number;
  almostValue: number;
  examNameList?: ExamModel[];
}

const DashboardCourseScoreItem: FC<Props> = ({
  title,
  attemptsValue,
  passedValue,
  failedValue,
  almostValue,
  examNameList,
}) => {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className={`b-dashboardCourseScoreItem ${isOpen ? 'state_open' : ''}`}>
      <button
        className="b-dashboardCourseScoreItem__header"
        onClick={() => setOpen(!isOpen)}
      >
        <span className="b-dashboardCourseScoreItem__title">{title}</span>
        <SectionArrow fontSize={'large'} className="b-dashboardCourseScoreItem__arrow" />

        <span className="b-dashboardCourseScoreItem__listInfo">
          <span className="b-dashboardCourseScoreItem__listItem">
            <img
              src={attempts_ic}
              alt="Attempts"
              className="b-dashboardCourseScoreItem__listIcon"
            />
            <span className="b-dashboardCourseScoreItem__listText">Attempts</span>
            <span className="b-dashboardCourseScoreItem__listValue">{attemptsValue}</span>
          </span>
          <span className="b-dashboardCourseScoreItem__listItem">
            <img
              src={passed_ic}
              alt="Passed"
              className="b-dashboardCourseScoreItem__listIcon"
            />
            <span className="b-dashboardCourseScoreItem__listText">Passed</span>
            <span className="b-dashboardCourseScoreItem__listValue">{passedValue}</span>
          </span>
          <span className="b-dashboardCourseScoreItem__listItem">
            <img
              src={failed_ic}
              alt="Failed"
              className="b-dashboardCourseScoreItem__listIcon"
            />
            <span className="b-dashboardCourseScoreItem__listText">Failed</span>
            <span className="b-dashboardCourseScoreItem__listValue">{failedValue}</span>
          </span>
          <span className="b-dashboardCourseScoreItem__listItem">
            <img
              src={almost_done_ic}
              alt="Almost Done"
              className="b-dashboardCourseScoreItem__listIcon"
            />
            <span className="b-dashboardCourseScoreItem__listText">Almost done</span>
            <span className="b-dashboardCourseScoreItem__listValue">{almostValue}</span>
          </span>
        </span>
      </button>

      <div
        className={`b-dashboardCourseScoreItem__contentWrapper ${
          isOpen ? 'state_open' : ''
        }`}
      >
        {examNameList?.length ? (
          <div className="b-dashboardCourseScoreItem__content">
            <ExamScoreTable examNameList={examNameList} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default DashboardCourseScoreItem;
