import React from 'react';
import CustomTooltip from '../CustomTooltip/CustomTooltip';

const RADIUS = 15;
const CIRCUMFERENCE = 2 * 3.14 * RADIUS;

interface IProps {
  percentage: number;
  marginTop: number;
}

const ProgressCircle = ({ percentage, marginTop }: IProps) => {
  return (
    <CustomTooltip title={`Answered correctly: ${percentage}%`}>
      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        className="progress-circle"
        style={{ marginTop: `-${marginTop}px` }}
      >
        <circle
          r={RADIUS}
          cx="26"
          cy="26"
          fill="transparent"
          stroke="var(--border-color2)"
          strokeWidth="21"
        ></circle>
        <circle
          r={RADIUS}
          cx="26"
          cy="26"
          fill="transparent"
          stroke="#61D9EA"
          strokeWidth="21"
          strokeDasharray={CIRCUMFERENCE}
          strokeDashoffset={CIRCUMFERENCE * ((100 - percentage) / 100)}
        ></circle>
      </svg>
    </CustomTooltip>
  );
};

export default ProgressCircle;
