import { Tooltip } from '@mui/material';
import React from 'react';
import useIsDarkTheme from '../../../hooks/use-is-dark-theme';

interface IProps {
  title: React.ReactNode;
  children: React.ReactElement;
  isWide?: boolean;
}

const CustomTooltip = ({ title, children, isWide = false }: IProps) => {
  const isDarkTheme = useIsDarkTheme();

  return (
    <Tooltip
      title={title || ''}
      arrow
      placement={'top'}
      componentsProps={{
        tooltip: {
          sx: {
            color: isDarkTheme ? '#FFFFFF' : '#1E2527',
            backgroundColor: isDarkTheme ? '#1E2527' : '#FFFFFF',
            boxShadow: '0 0 16px var(--shadow-color2-0)',
            maxWidth: isWide ? 'none' : undefined,
            width: isWide ? '500px' : undefined,
          },
        },
        arrow: {
          sx: {
            color: isDarkTheme ? '#1E2527 !important' : '#FFFFFF !important',
            '&:before': {
              backgroundColor: isDarkTheme ? '#1E2527 !important' : '#FFFFFF !important',
            },
          },
        },
      }}
    >
      {children}
    </Tooltip>
  );
};

export default CustomTooltip;
