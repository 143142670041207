import React from 'react';
import active from '../../../assets/images/dashboard/active_illustration.svg';
import completed from '../../../assets/images/dashboard/completed_illustration.svg';
import expired from '../../../assets/images/dashboard/expired_illustration.svg';
import examscores from '../../../assets/images/dashboard/exam_scores.svg';
import audio from '../../../assets/images/dashboard/audio_illustration.svg';
import flashCards from '../../../assets/images/dashboard/flash_cards_illustration.svg';
import tailoredTesting from '../../../assets/images/dashboard/tailored_testing_illustration.svg';

interface ICourseInformation {
  status: string | null;
}

const data = {
  active: {
    img: active,
    title: 'You don’t have any active course',
    subtitle: 'When you start a course, it will appear here.',
  },
  complete: {
    img: completed,
    title: "You haven't completed any courses yet.",
    subtitle: 'When you finish a course, it will appear here.',
  },
  expired: {
    img: expired,
    title: 'None of your courses have expired yet.',
    subtitle: 'Once they do, they will appear here.',
  },
  exam: {
    img: examscores,
    title: 'You have not completed an exam in a course yet.',
    subtitle: 'Once you do, your results will be accessible here.',
  },
  audio: {
    img: audio,
    title: 'You must have an active course in order to play audios',
    subtitle: 'Please purchase or renew a course to access.',
  },
  tailoredTesting: {
    img: tailoredTesting,
    title: 'You don’t have any questions',
    subtitle: 'When you start a course, it will appear here.',
  },
  flashCards: {
    img: flashCards,
    title: 'You currently have no active courses available for Flashcards.',
    subtitle: 'Please purchase or renew a course to gain access.',
  },
};

const CourseInformation: React.FC<ICourseInformation> = ({
  status,
}: ICourseInformation) => {
  const getStatusData = () => {
    if (status === 'active') return data.active;
    if (status === 'complete') return data.complete;
    if (status === 'expired') return data.expired;
    if (status === 'exam') return data.exam;
    if (status === 'audio') return data.audio;
    if (status === 'tailoredTesting') return data.tailoredTesting;
    if (status === 'flashCards') return data.flashCards;
    else return data.complete;
  };

  const statusData = getStatusData();

  return (
    <div className={'b-dashboard__courseInformation'}>
      <img className={'b-dashboard__courseInformationImg'} src={statusData.img} />
      <div className={'b-dashboard__courseInformationTitle'}>{statusData.title}</div>
      <div className={'b-dashboard__courseInformationSubTitle'}>
        {statusData.subtitle}
      </div>
    </div>
  );
};

export default CourseInformation;
