import React, { Fragment } from 'react';
import { PositionsByCategoryModel, SelectedExamCategoriesModel } from '../../../models';
import { AdvancedData, CategoryAchievements } from '../index';
import { Checkbox, FormControlLabel } from '@mui/material';
import { KeyValueModel } from '../../../models/global';

interface IProps {
  categoriesList: PositionsByCategoryModel[];
  isSubCategory: boolean;
  clickCallback: (c: PositionsByCategoryModel) => void;
  selectedCategories: KeyValueModel<SelectedExamCategoriesModel>;
  openedCategory: PositionsByCategoryModel | null;
  handleLeaderboardClick: (
    event: any,
    categoryId: number,
    subCategoryId?: number,
  ) => void;
}

const SubCategoryTitle = ({
  categoriesList,
  isSubCategory,
  clickCallback,
  selectedCategories,
  openedCategory,
  handleLeaderboardClick,
}: IProps) => {
  return (
    <>
      {categoriesList.map(c => {
        const isChecked = isSubCategory
          ? Boolean(
              selectedCategories[openedCategory!.category_id]?.sub_categories?.includes(
                c.category_id,
              ),
            )
          : Boolean(selectedCategories[c.category_id]);
        return (
          <div key={`question-${c.position}`} className="category">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex align-items-center w-100">
                  <FormControlLabel
                    className="check"
                    control={
                      <Checkbox
                        checked={isChecked}
                        onChange={() => clickCallback(c)}
                        sx={{
                          color: 'var(--text-color)',
                        }}
                      />
                    }
                    label={<span className="title">{c.category_name}</span>}
                  />
                </div>
              </div>
              <CategoryAchievements category={c} isSubCategory={true} />
            </div>
            <AdvancedData
              parentCategoryId={openedCategory?.category_id}
              category={c}
              handleLeaderboardClick={handleLeaderboardClick}
            />
          </div>
        );
      })}
    </>
  );
};

export default SubCategoryTitle;
